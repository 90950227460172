import React, { Component } from 'react';
import "../../Home/styles/ContentHome.css";
import "../styles/Profile.css";
import CardGridGrouping from "../../Common/components/CardGridGrouping";
import ProfileSummary from '../../Profile/components/Summary';
import Error from '../../Common/components/Error';
import { CardHeader } from 'reactstrap';
import { Link } from 'react-router-dom';
import Loader from '../../Common/components/Loader';
import { isEmpty } from 'lodash';

class Profile extends Component {

	constructor(props) {
        super(props);
        this.state = {
            setInitialFocus: false
        };
	}

	componentDidMount() {
		this.props.fetchUserAssociation();
		this.props.fetchCompletedContent();
		this.props.fetchCardData();
	}

	componentDidUpdate(prevProps) {
		if(this.pageHeading && this.state.setInitialFocus === false) {
            this.pageHeading.focus();
            this.setState({ setInitialFocus: true });
        }
    }

	render() {
		if ([this.props.userHasError, this.props.completedContentHasError].some(Boolean)) {
			return (
				<div className="container-fluid row h-100 justify-content-center align-items-center m-5">
					<Error errorMessage={this.props.userHasError ? "Error has occured. Please refresh the page." : ""} />
				</div>
			);
		}
		if (this.props.completedContent && !isEmpty(this.props.user)) {
			const contentWithHandler = this.props.completedContent.map(content => {
				content.callback = this.props.handleContentCardClick;
				content.completed = !!this.props.user.content[content.id] ||
					(!!this.props.user.achievements[content.id] && this.props.user.achievements[content.id].status === "COMPLETE");
				content.percent = this.props.user.achievements[content.id] && this.props.user.achievements[content.id].progress; //returns progress
				return content;
			});
			const allCompletedContent = contentWithHandler.filter(content => {
				if(content.type === "achievement") {
					return content.completed
				} else {
					return content
				}
			})
			let achievementContent = contentWithHandler
				.filter(content => content.type === 'achievement' && content.completed && content.contentItemsRequired && content.contentItemsRequired.length > 0);

			return (
				<div>
					<h2 ref={el => (this.pageHeading = el)} tabIndex="-1" className="sr-only">User Profile</h2>
					<div className="row profile-info">
						<div className="col-12 mb-4">
							<ProfileSummary fullWidth={true} displayName={this.props.user.displayName} title={this.props.userAssociation.title}
								area={this.props.userAssociation.area} points={this.props.user.points} userId={this.props.user.id}
								optOut={this.props.user.optOut} updateOptOut={this.props.updateUserOptOut} updateOptOutState={this.props.updateUserOptOutState}
								achievements={achievementContent} totalPoints={this.props.content.reduce((acc, contentItem) => acc + contentItem.points, 0)}>
								<CardHeader className="action-sheet">
									<Link className="osu-link-secondary" to={'/purchases'}>View Purchase History</Link >
								</CardHeader>
							</ProfileSummary>
						</div>
					</div>
					<h2>Your History</h2>
					{contentWithHandler.length > 0 ?
						<CardGridGrouping content={allCompletedContent} appendToTitle="Completed" isProfile={true} />
						: <div>
							<p className="profile-history-empty">Start an achievement or activity!</p>
						</div>
					}
				</div>
			);
		} else {
			return (<Loader />);
		}
	}
}

export default Profile;