import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useIdleTimer } from 'react-idle-timer';
import { useLocation } from 'react-router-dom';
import Navbar from 'react-osu-navbar'
import Header from '../../Header/components';
import Main from '../../Main/containers'
import Footer from "./Footer";
import { isEmpty } from "lodash";
import { ACTION_STATUS_ERROR, ACTION_STATUS_LOADING, ACTION_STATUS_SUCCESS } from "../../util/constants";
import "../styles/App.css";

const TIMEOUT = 60000 * (parseInt(process.env.REACT_APP_TIMEOUT) || 120);

function App(props = {}) {
  const { cart, isAuthenticated, setAuthUser, signOut, authUser, user } = props;
  const { status: authUserStatus } = authUser;
  const location = useLocation();
  const { pathname } = location;
  const handleOnIdle = event => {
    console.log('user is idle', event)
    console.log('last active', getLastActiveTime())
    signOut()
  }
  const { getLastActiveTime } = useIdleTimer({
    timeout: TIMEOUT,
    onIdle: handleOnIdle
  })
  const isSupportedBrowser = !(!navigator || !navigator.userAgent || navigator.userAgent.indexOf("Trident") > -1);

  useEffect(() => {
    if(isEmpty(authUserStatus) && !["/login", "/logout"].includes(pathname)) setAuthUser(true)
  }, [pathname, setAuthUser, authUserStatus]);

  return (
      <div className={`App`}>
        <Navbar/>
        {(isSupportedBrowser && isAuthenticated) && <Header className="app-nav-container" cartCount={cart.rewards.length} user={user} location={location} signOut={signOut} /> }
        <Main id="page-content" className="container my-1" isSupportedBrowser={isSupportedBrowser} />
        <Footer />
      </div>
  );
}

App.defaultProps = {
  isAuthenticated: false,
  authUser: {
    status: "",
  },
};

App.propTypes = {
  isAuthenticated: PropTypes.bool,
  authUser: PropTypes.shape({
    status: PropTypes.oneOf([ACTION_STATUS_ERROR, ACTION_STATUS_LOADING, ACTION_STATUS_SUCCESS, ""]),
  }),
  setAuthUser: PropTypes.func,
  signOut: PropTypes.func
};

export default App;
