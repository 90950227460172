import React, {Component} from 'react';
import ReactLoading from 'react-loading';
import '../styles/Loader.css';

class Loader extends Component {
    render() {
        return (
            <div className="vertical-center">
                <div className="d-flex align-items-center justify-content-center col-12">
                    <div className="row">
                        <ReactLoading alt="Loading" type={"spin"} color={"var(--osu-gray)"} height={'40px'} width={'50px'}/>
                    </div>
                </div>
            </div>
        );
    }
}

export default Loader;