import React from 'react';
import PropTypes from 'prop-types';
import '../styles/Stepper.css';

export const Stepper = (props) => {
    return <div className={`${props.className} step-container`}>
        {props.children}
    </div>
}

export const StepperSection = (props) => {
    const { inactive = false, circleContent, heading, children } = props

    return <div className="mb-2 step">
        <div className="d-flex flex-row align-items-center mb-1">
            <div className="section-circle">
                <span className="circle-text">{circleContent}</span>
            </div>
            <div className="section-heading">
                <h2>{heading}</h2>
            </div>
        </div>
        <div className={`section-body pb-4`}>
            {!inactive ? children : null}
        </div>
    </div>
}

Stepper.propTypes = {
    children: function (props, propName, componentName) {
        const prop = props[propName]

        let error = null
        React.Children.forEach(prop, function (child) {
            if (child.type !== StepperSection) {
                error = new Error('`' + componentName + '` children should be of type `StepperSection`.');
            }
        })
        return error
    }
}

StepperSection.propTypes = {
    heading: PropTypes.string.isRequired,
    inactive: PropTypes.bool,
    className: PropTypes.string
}