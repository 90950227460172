import { USER_HAS_ERROR, USER_NO_ERROR, USER_IS_LOADING, USER_FETCH_DATA_SUCCESS, CONTENT_COMPLETE_POST_SUCCESS,
    UPDATE_USER_OPTOUT, UPDATE_USER_AVAILABLE_LEVELS, FEATURES_HAVE_ERROR, FEATURES_ARE_LOADING, FEATURES_FETCH_DATA_SUCCESS,
    FEATURE_HAS_ERROR, FEATURE_IS_LOADING, FEATURE_SELECTED } from "../actions-index";

export function userHasError(state = false, action) {
    switch (action.type) {
        case USER_HAS_ERROR:
        case USER_NO_ERROR:
            return action.hasError;

        default:
            return state;
    }
}

export function userIsLoading(state = false, action) {
    switch (action.type) {
        case USER_IS_LOADING:
            return action.isLoading;
        default:
            return state;
    }
}

export function user(state = {}, action = {}) {
    switch (action.type) {
        case USER_FETCH_DATA_SUCCESS:
        case CONTENT_COMPLETE_POST_SUCCESS:
        case UPDATE_USER_OPTOUT:
            return action.user;
        case UPDATE_USER_AVAILABLE_LEVELS:
            var availableLevels = state.availableLevels.filter( (level) => !~action.rewardLevels.indexOf(parseInt(level, 10)) );
            return { ...state, availableLevels };
        default:
            return state;
    }
}

export function featuresHaveError(state = false, action) {
    switch (action.type) {
        case FEATURES_HAVE_ERROR:
            return action.hasError;

        default:
            return state;
    }
}

export function featuresAreLoading(state = false, action) {
    switch (action.type) {
        case FEATURES_ARE_LOADING:
            return action.isLoading;

        default:
            return state;
    }
}

export function features(state = [], action) {
    switch (action.type) {
        case FEATURES_FETCH_DATA_SUCCESS:
            return action.features;

        default:
            return state;
    }
}

export function featureHasError(state = false, action) {
    switch (action.type) {
        case FEATURE_HAS_ERROR:
            return action.hasError;

        default:
            return state;
    }
}

export function featureIsLoading(state = false, action) {
    switch (action.type) {
        case FEATURE_IS_LOADING:
            return action.isLoading;

        default:
            return state;
    }
}

export function selectedFeature(state = null, action) {
    switch (action.type) {
        case FEATURE_SELECTED:
            return action.feature;

        default:
            return state;
    }
}