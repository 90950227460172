import React, { Component } from 'react';
import "../styles/Nav.css";
import { Row, Col } from 'reactstrap';
import RewardsSidebar from '../components/Sidebar';
import RewardsHorizontalBar from '../components/HorizontalBar';

class RewardsNavContainer extends Component {
    render() {
            const appendingIdentifierForSectionNavigation = "sectionId";

            return (
                <div>
                    <Row className="sticky-rewards-nav">
                        <Col xs="12">
                            <RewardsHorizontalBar cartCount={this.props.cartCount ? this.props.cartCount : 0} />
                        </Col>
                    </Row>
                    <Row>
                        <Col className="pl-0 sticky-side-bar" lg="2">
                            <RewardsSidebar disabledLevelLinks={this.props.disabledLevelLinks} className="p-4 d-none d-lg-block" cartCount={this.props.cartCount ? this.props.cartCount : 0} appendingIdentifier={appendingIdentifierForSectionNavigation} levels={this.props.availableLevels} pathname={this.props.pathname} />
                        </Col>
                        <Col xs="12" lg="10">
                           {this.props.children}
                        </Col>
                    </Row>
                </div>
            );
        }
    }

export default RewardsNavContainer;