import React from "react";

export default function Footer() {
    return (
        <footer className="footer-dk-gray pb-4">
            <div className="row no-row-margins pt-3">
                <div className="col-12 col-sm-7 d-flex">
                    <div className="ml-auto mr-auto mr-md-4 ml-md-0">
                        <div className="col-sm-12 text-center text-sm-left">
                            <a href="http://osu.edu" target="_blank" rel="noreferrer">
                                <img className="osu-logo" src="https://cybersecurity.osu.edu/profiles/wcm_base/themes/wcm_omega/images/osu-logos/osu-footer-wordmark-dk-gray.svg"
                                    alt="The Ohio State University" />
                            </a>
                        </div>
                        <div className="col-sm-12 mb-2 text-center text-sm-left">
                            <p>Cybersecurity For You</p>
                            <p>Email: <a href="mailto:c4u@osu.edu">C4U@osu.edu</a></p>
                            <p><a href="https://www.osu.edu/privacy/">Privacy Policy</a></p>
                        </div>
                        <div className="col-sm-12 small text-center text-sm-left">
                            <p>
                                If you rely on assistive technology or a screen reader you will have difficulty using this site.
                                While we work to make this site fully accessible, you can obtain a fully accessible alternative 
                                by contacting the Accessibility Help Line at 614-292-5000.                        
                            </p>
                        </div>
                    </div>
                </div>
                <div className="col-12 col-sm-5 mt-2">
                    <div className="col-12 d-flex">
                        <ul className="osu-siteinfo-social mr-auto ml-auto mr-md-0">
                            <li>
                                <a href="https://twitter.com/TechOhioState" target="_blank" rel="noreferrer" className="siteinfo-social-link link-twitter">
                                    <span className="element-invisible">Twitter</span>
                                    <span className="fab fa-lg fa-twitter" />
                                </a>
                            </li>
                            <li>
                                <a href="https://www.youtube.com/user/TechOhioState" target="_blank" rel="noreferrer" className="siteinfo-social-link link-youtube">
                                    <span className="element-invisible">YouTube</span>
                                    <span className="fab fa-lg fa-youtube" />
                                </a>
                            </li>
                            <li>
                                <a href="https://www.instagram.com/techohiostate/" target="_blank" rel="noreferrer" className="siteinfo-social-link link-instagram">
                                    <span className="element-invisible">Instagram</span>
                                    <span className="fab fa-lg fa-instagram" />
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </footer>
    );
}