export const SET_USER = 'SET_USER';
export const USER_STATUS = 'USER_STATUS';
export const ADD_TO_CART = 'ADD_TO_CART';
export const REMOVE_FROM_CART = 'REMOVE_FROM_CART';
export const USER_ADDRESS_HAS_ERROR = 'USER_ADDRESS_HAS_ERROR';
export const USER_ADDRESS_IS_LOADING = 'USER_ADDRESS_IS_LOADING';
export const USER_ADDRESS_FETCH_DATA_SUCCESS = 'USER_ADDRESS_FETCH_DATA_SUCCESS';
export const PICKUP_LOCATIONS_HAS_ERROR = 'PICKUP_LOCATIONS_HAS_ERROR';
export const PICKUP_LOCATIONS_IS_LOADING = 'PICKUP_LOCATIONS_IS_LOADING';
export const PICKUP_LOCATIONS_FETCH_DATA_SUCCESS = 'PICKUP_LOCATIONS_FETCH_DATA_SUCCESS';
export const UPDATE_USER_AVAILABLE_LEVELS = 'UPDATE_USER_AVAILABLE_LEVELS';
export const ACHIEVEMENT_SELECTED = 'ACHIEVEMENT_SELECTED';
export const ACHIEVEMENTS_HAVE_ERROR = 'ACHIEVEMENTS_HAVE_ERROR';
export const ACHIEVEMENTS_ARE_LOADING = 'ACHIEVEMENTS_ARE_LOADING';
export const ACHIEVEMENTS_FETCH_DATA_SUCCESS = 'ACHIEVEMENTS_FETCH_DATA_SUCCESS';
export const CATEGORY_SELECTED = 'CATEGORY_SELECTED';
export const CATEGORIES_HAVE_ERROR = 'CATEGORIES_HAVE_ERROR';
export const CATEGORIES_NO_ERROR = 'CATEGORIES_NO_ERROR';
export const CATEGORIES_ARE_LOADING = 'CATEGORIES_ARE_LOADING';
export const CATEGORIES_FETCH_DATA_SUCCESS = 'CATEGORIES_FETCH_DATA_SUCCESS';
export const CONTENT_LIST_HAS_ERROR = 'CONTENT_LIST_HAS_ERROR';
export const CONTENT_LIST_IS_LOADING = 'CONTENT_LIST_IS_LOADING';
export const CONTENT_LIST_FETCH_DATA_SUCCESS = 'CONTENT_LIST_FETCH_DATA_SUCCESS';
export const CONTENT_HAS_ERROR = 'CONTENT_HAS_ERROR';
export const CONTENT_IS_LOADING = 'CONTENT_IS_LOADING';
export const CONTENT_FETCH_DATA_SUCCESS = 'CONTENT_FETCH_DATA_SUCCESS';
export const COMPLETED_CONTENT_HAS_ERROR = 'COMPLETED_CONTENT_HAS_ERROR';
export const COMPLETED_CONTENT_NO_ERROR = 'COMPLETED_CONTENT_NO_ERROR';
export const COMPLETED_CONTENT_IS_LOADING = 'COMPLETED_CONTENT_IS_LOADING';
export const COMPLETED_CONTENT_FETCH_DATA_SUCCESS = 'COMPLETED_CONTENT_FETCH_DATA_SUCCESS';
export const CONTENT_COMPLETE_POST_IS_LOADING = 'CONTENT_COMPLETE_POST_IS_LOADING';
export const CONTENT_COMPLETE_POST_SUCCESS = 'CONTENT_COMPLETE_POST_SUCCESS';
export const CONTENT_COMPLETE_POST_HAS_ERROR = 'CONTENT_COMPLETE_POST_HAS_ERROR';
export const CONTENT_ALL_CARD_DATA_IS_LOADING = 'CONTENT_ALL_CARD_DATA_IS_LOADING';
export const CONTENT_ALL_CARD_DATA_SUCCESS = 'CONTENT_ALL_CARD_DATA_SUCCESS';
export const CONTENT_ALL_CARD_DATA_HAS_ERROR = 'CONTENT_ALL_CARD_DATA_HAS_ERROR';
export const CONTENT_ALL_CARD_DATA_NO_ERROR = 'CONTENT_ALL_CARD_DATA_NO_ERROR';
export const CLEAR_CONTENT_STATE = 'CLEAR_CONTENT_STATE';
export const QUIZ_DATA_IS_LOADING = 'QUIZ_DATA_IS_LOADING';
export const QUIZ_DATA_SUCCESS = 'QUIZ_DATA_SUCCESS';
export const QUIZ_DATA_HAS_ERROR = 'QUIZ_DATA_HAS_ERROR';
export const QUIZ_DATA_NO_ERROR = 'QUIZ_DATA_NO_ERROR';
export const QUIZ_SUBMIT_IS_LOADING = 'QUIZ_SUBMIT_IS_LOADING';
export const QUIZ_SUBMIT_SUCCESS = 'QUIZ_SUBMIT_SUCCESS';
export const QUIZ_SUBMIT_HAS_ERROR = 'QUIZ_SUBMIT_HAS_ERROR';
export const CLEAR_QUIZ_SUBMIT_STATE = 'CLEAR_QUIZ_SUBMIT_STATE';
export const USER_HAS_ERROR = 'USER_HAS_ERROR';
export const USER_NO_ERROR = 'USER_NO_ERROR';
export const USER_IS_LOADING = 'USER_IS_LOADING';
export const USER_FETCH_DATA_SUCCESS = 'USER_FETCH_DATA_SUCCESS';
export const FEATURES_HAVE_ERROR = 'FEATURES_HAVE_ERROR';
export const FEATURES_ARE_LOADING = 'FEATURES_ARE_LOADING';
export const FEATURES_FETCH_DATA_SUCCESS = 'FEATURES_FETCH_DATA_SUCCESS';
export const FEATURE_HAS_ERROR = 'FEATURE_HAS_ERROR';
export const FEATURE_IS_LOADING = 'FEATURE_IS_LOADING';
export const FEATURE_SELECTED = 'FEATURE_SELECTED';
export const LEADERBOARD_DATA_IS_LOADING = 'LEADERBOARD_DATA_IS_LOADING';
export const LEADERBOARD_DATA_SUCCESS = 'LEADERBOARD_DATA_SUCCESS';
export const LEADERBOARD_DATA_HAS_ERROR = 'LEADERBOARD_DATA_HAS_ERROR';
export const LEADERBOARD_DATA_NO_ERROR = 'LEADERBOARD_DATA_NO_ERROR';
export const LEADERBOARD_USER_DATA_IS_LOADING = 'LEADERBOARD_USER_DATA_IS_LOADING';
export const LEADERBOARD_USER_DATA_SUCCESS = 'LEADERBOARD_USER_DATA_SUCCESS';
export const LEADERBOARD_USER_DATA_HAS_ERROR = 'LEADERBOARD_USER_DATA_HAS_ERROR';
export const LEADERBOARD_USER_DATA_NO_ERROR = 'LEADERBOARD_USER_DATA_NO_ERROR';
export const USER_ASSOCIATION_HAS_ERROR = 'USER_ASSOCIATION_HAS_ERROR';
export const USER_ASSOCIATION_IS_LOADING = 'USER_ASSOCIATION_IS_LOADING';
export const USER_ASSOCIATION_FETCH_DATA_SUCCESS = 'USER_ASSOCIATION_FETCH_DATA_SUCCESS';
export const UPDATE_USER_OPTOUT_STATE = 'UPDATE_USER_OPTOUT_STATE';
export const UPDATE_USER_OPTOUT = 'UPDATE_USER_OPTOUT';
export const PURCHASE_SUBMIT_HAS_ERROR = 'PURCHASE_SUBMIT_HAS_ERROR';
export const PURCHASE_SUBMIT_SUCCESS = 'PURCHASE_SUBMIT_SUCCESS';
export const PURCHASE_DATA_SUCCESS = 'PURCHASE_DATA_SUCCESS';
export const PURCHASE_FETCH_HAS_ERROR = 'PURCHASE_FETCH_HAS_ERROR';
export const PURCHASE_FETCH_DATA_IS_LOADING = 'PURCHASE_FETCH_DATA_IS_LOADING';
export const REWARDS_DATA_IS_LOADING = 'REWARDS_DATA_IS_LOADING';
export const REWARDS_DATA_SUCCESS = 'REWARDS_DATA_SUCCESS';
export const REDEEMED_REWARDS_DATA_SUCCESS = 'REDEEMED_REWARDS_DATA_SUCCESS';
export const REWARDS_DATA_HAS_ERROR = 'REWARDS_DATA_HAS_ERROR';

export * from './Authentication/actions';
export * from './Cart/actions';
export * from './Content/actions';
export * from './Home/actions';
export * from './Leaderboard/actions';
export * from "./Profile/actions";
export * from './Purchases/actions';
export * from './Rewards/actions';