import { userFetchData, SET_USER, USER_STATUS } from "../actions-index";
import { Auth } from 'aws-amplify';
import { redirectToLogIn, redirectToLogOut } from './util';
import { transformUser } from "./transform";
import { ACTION_STATUS_ERROR, ACTION_STATUS_LOADING } from "../util/constants";
import { buildAction } from "../util/util";

export function recievedSetUser(user) {
    return buildAction(SET_USER, transformUser(user));
}

export function setUserStatus(status) {
    return buildAction(USER_STATUS, status);
}

export function authSignOut() {
    return function (dispatch) {
        Auth.signOut();
        redirectToLogOut();
        dispatch(setUserStatus(ACTION_STATUS_ERROR));
        dispatch(buildAction(SET_USER, null));
    }
}

export function setUser(forceRedirect = false) {
    return function (dispatch) {
        dispatch(setUserStatus(ACTION_STATUS_LOADING))
        function validateSession(userResponse) {
            if (userResponse) {
                dispatch(recievedSetUser(userResponse));
                dispatch(userFetchData());
            }
            return
        }

        return Auth.currentSession()
            .then((session) => {
                validateSession(session.idToken.payload)
            })
            .catch((error = {}) => {
                let { code } = error
                if (forceRedirect && (error === 'No current user' || code === "NotAuthorizedException")) {
                    redirectToLogIn();
                } else {
                    setTimeout(() => {
                        Auth.currentSession()
                            .then((session) => {
                                validateSession(session.idToken.payload)
                            })
                            .catch((error) => {
                                setTimeout(() => {
                                    Auth.currentSession()
                                        .then((session) => {                                            
                                            validateSession(session.idToken.payload)
                                        })
                                        .catch((error) => {
                                            console.log(error)
                                            dispatch(setUserStatus(ACTION_STATUS_ERROR))
                                        });
                                }, 3000);//second wait
                            });
                    }, 1000);
                }
            })
    }
}