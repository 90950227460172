import React, {Component} from 'react';
var message;

class Error extends Component {
    render() {
        message = this.props.errorMessage ? this.props.errorMessage : "Error has occurred. Please refresh the page.";
        return (
            
            <div className="container-fluid">
                <img src="https://security-incentive-images.s3.us-east-2.amazonaws.com/brutus-3.png" alt="brutus error" width="150"/>
                <p> {message} </p>
            </div>
        );
    }
}

export default Error;