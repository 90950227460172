import React, { Component } from 'react';
import { Button, Row, Col } from 'reactstrap';
import { Field } from 'redux-form';
import '../styles/Assessment.css';
import FeedbackCard from './FeedbackCard';
import { Element, scroller } from "react-scroll";
import Error from '../../Common/components/Error';
import Icon from '../../Common/components/Icon';
import levelInformation from '../../util/levelInformation';

let _ = require('lodash');
const QUESTION_PREFIX = "question-";

class Assessment extends Component {
    constructor(props) {
        super(props);
        this.submitQuiz = this.submitQuiz.bind(this);
        this.renderAnswer = this.renderAnswer.bind(this);
        this.renderRadioButton = this.renderRadioButton.bind(this);
        this.state = {
            modal: true
        };
        this.toggle = this.toggle.bind(this);
        this.handleClick = this.handleClick.bind(this);
    }

    toggle() {
        this.setState({
            modal: !this.state.modal
        });
    }

    handleClick() {
        this.setState({
            modal: !this.state.modal
        });
    }

    submitQuiz(values) {
        var contentId = this.props.content.id;
        var id = this.props.quiz.quizId;
        var questionResponses = {};
        for (var property in values) {
            questionResponses[property.substr(QUESTION_PREFIX.length)] = values[property];
        }
        this.props.submitTheQuiz({ id, questionResponses, contentId });

    }

    renderRadioButton(field) {
        const { input, disabled } = field;
        return (
            <input id={`${input.name + "-" + field.answer.answerId}`} type="radio" {...input} onChange={() => this.props.change(input.name, field.answer.answerId)} required disabled={disabled} />
        );
    }

    renderAnswer(field) {
        const { input, answer, disabled, meta: { touched, error, warning } } = field;
        return (
            <div>
                <label htmlFor={`${input.name + "-" + answer.answerId}`} aria-labelledby={answer.id} className={error && touched ? "text-danger" : ""}>
                    <Field component={this.renderRadioButton} {...input} answer={answer} disabled={disabled} />
                    <span className="make-me-a-button mr-2"></span>
                    <span id={answer.id} className="middle">{answer.answerText}</span>

                </label>
                <div>
                    {touched &&
                        ((error && <span className="text-danger">{error}</span>) ||
                            (warning && <span className="text-warning">{warning}</span>))}
                </div>

            </div>
        )
    }

    componentWillReceiveProps(newProps) {
        if (newProps.quizResult.quizComplete && !newProps.completed) {
            this.scrollTo();
        }
    }


    componentDidMount() {
        this.props.clearQuizSubmission();
    }

    componentWillUnmount() {
        this.props.clearQuizSubmission();
    }

    scrollTo() {
        scroller.scrollTo("scroll-to-element", {
            duration: 1500,
            delay: 0,
            offset: -60,
            smooth: "easeInOutCubic"
        });

        return true;
    }

    renderFeedback() {
        return <FeedbackCard />
    }

    render() {
        let titles = [];
        if (!_.isEmpty(this.props.user.achievements) && !_.isEmpty(this.props.content.achievements)) {
            this.props.content.achievements.forEach(achievement => {
                const matchingAchievement = this.props.user.achievements[achievement.id];
                if (matchingAchievement && matchingAchievement.progress > 99) {
                    titles = [...titles, achievement.title]
                }
            }
            )
        }

        const userPoints = isNaN(this.props.user.points) ? 0 : this.props.user.points;
        const { currentPoints, userLevel } = levelInformation.calculate(userPoints); 

        if (this.props.quizHasError) {
            return (
                <div className="container-fluid row h-100 justify-content-center align-items-center m-5">
                    <Error />
                </div>
            )
        }

        return (            
            < div >
                {Boolean(this.props.quiz.quizName) &&
                    <div className="p-4 paper">
                        {this.props.completed ?
                            <div className="mt-2 mr-3 ml-3 mb-2 block-me">
                                <Icon type="check-circle" size="3x" className="middle text-success mr-2" />
                                <span className="complete-title">Quiz Complete!
                                </span>
                            </div>
                            : ""}
                        {!this.props.completed ? (
                            <div className="mt-2 mr-3 ml-3 mb-2">
                                <h2 className="mt-2">{this.props.quiz.quizName}</h2>
                                <h3 className="quiz-desc">{this.props.quiz.quizDescription}</h3>
                                <form onSubmit={this.props.handleSubmit(this.submitQuiz)}>
                                    {this.props.quiz && this.props.quiz.questions &&
                                        this.props.quiz.questions.map((question, index) => {
                                            var questionResult = null;
                                            if (this.props.quizResult.result && this.props.quizResult.result[question.questionId]) {
                                                questionResult = this.props.quizResult.result[question.questionId].correct;
                                            }
                                            var questionIcon = (questionResult == null) ? 
                                                null : 
                                                questionResult ? 
                                                    <Icon type="check-circle" className="text-success questionResult" /> :
                                                    <Icon type="times-circle" className="text-danger questionResult" />;
                                            if (question.answers) {
                                                var answers = question.answers.map((answer, answerIndex) => {
                                                    return <Field
                                                        name={QUESTION_PREFIX + `${question.questionId}`}
                                                        index={answerIndex}
                                                        answer={answer}
                                                        key={answerIndex}
                                                        component={this.renderAnswer}
                                                        disabled={this.props.quizResult.correctCount || !isNaN(this.props.quizResult.correctCount)}
                                                    />
                                                });
                                            }
                                            return (
                                                <fieldset key={index}>
                                                    <legend className="question">{questionIcon}{question.questionText}</legend>
                                                    <div className="form-group">
                                                        {answers}
                                                    </div>
                                                </fieldset>
                                            )
                                        })
                                    }
                                    <hr />
                                    {!isNaN(this.props.quizResult.correctCount) && this.props.quizResult.totalQuestions &&
                                        <div>{`${this.props.quizResult.correctCount} of ${this.props.quizResult.totalQuestions} correct!`}</div>}
                                    {!this.props.quizResult.result && <Button className="btn osu-red-btn" type="submit">Submit</Button>}
                                    {this.props.quizResult.result && !this.props.quizResult.quizComplete &&
                                        <Button className="btn osu-red-btn" type="button" onClick={() => window.location.reload()}>Retake
                                                Quiz</Button>}
                                </form>
                            </div>
                        ) : ""}
                    </div>
                }
                <Element name="scroll-to-element" className="element mt-4">
                    {this.props.quizResult.quizComplete ? (
                            <Row className="paper p-4 no-row-margins">
                                <Col className="mb-4 no-col-padding" xs="12" sm="6" md={titles && titles.length > 0 ? '4' : '6'}>
                                    <FeedbackCard badge="check" color="--osu-green" className="feedback-item">
                                        <p>
                                            {this.props.content.points <= 0 ? "Activity completed!" : `${this.props.content.points} Point${this.props.content.points > 1 || this.props.content.points === 0 ? "s" : ""} Earned`}
                                        </p>
                                    </FeedbackCard>
                                </Col>
                                <Col className="mb-4 no-col-padding" xs="12" sm="6" md={titles && titles.length > 0 ? '4' : '6'}>
                                    <FeedbackCard heading="your progress" className="feedback-item">
                                        <p>{userLevel < 1 ? 'Entry ' : ''}Level{userLevel >= 1 ? ` ${userLevel}` : ''}</p>
                                        <p>{`${currentPoints} Points`}</p>
                                    </FeedbackCard>
                                </Col>
                                {titles && titles.length > 0 ?
                                    <Col className="mb-4 no-col-padding" xs="12" md="4">
                                        <FeedbackCard badge="certificate" color="--osu-gray" heading="your achievements" className="feedback-item">
                                            {titles.map(title => <p key={title}>{`${title}`}</p>)}
                                        </FeedbackCard>
                                    </Col>
                                    : null}
                            </Row>
                        ) : ""}
                </Element>
            </div >
        );
    }

}

export default Assessment;