import { API } from "aws-amplify";
import { PURCHASE_SUBMIT_HAS_ERROR, PURCHASE_SUBMIT_SUCCESS, PURCHASE_DATA_SUCCESS, PURCHASE_FETCH_HAS_ERROR,
    PURCHASE_FETCH_DATA_IS_LOADING } from "../actions-index";

export function submitPurchase(values) {
    const { emplId, firstName, lastName, shippingAddress, rewards, username } = values
  
    let purchases = rewards.map(reward => {
        let purchase = {
            emplId,
            firstName,
            lastName,
            username,
            rewardId: reward.rewardId,
            rewardYear: reward.rewardYear.toString(),
            rewardLevel: reward.rewardLevel.toString(),
            shippingAddress
        };
        if(reward.categories && reward.category){
            purchase.category = reward.category;
        }
        return purchase;
    })

    return (dispatch) => {
        API.post(process.env.REACT_APP_API_NAME, "/reward/redeem", { body: purchases })
            .then((response) => {
                dispatch(purchaseSubmitSuccess(response.data))
            })
            .catch(() => dispatch(purchaseSubmitHasError(true)));
    }
}

export function purchaseSubmitHasError(bool) {
    return {
        type: PURCHASE_SUBMIT_HAS_ERROR,
        hasError: bool
    };
}

export function purchaseSubmitSuccess(purchases) {
    return {
        type: PURCHASE_SUBMIT_SUCCESS,
        purchases
    };
}

export function purchasesFetchData() {
    return (dispatch) => {
        dispatch(purchasesIsLoading(true));
        API.get(process.env.REACT_APP_API_NAME, "/user")
        .then((response) => {
            let emplId = response.data.id;
            API.post(process.env.REACT_APP_API_NAME, "/reward/redemptions", { body: { emplId } })
                .then((response) => {
                    dispatch(purchasesIsLoading(false));
                    dispatch(purchaseFetchDataSuccess(response.data))
                })
                .catch(() => dispatch(purchaseFetchHasError(true)));
        })
        .catch(() => dispatch(purchaseFetchHasError(true)))
    }
}

export function purchaseFetchDataSuccess(purchases) {
    return (dispatch) => {
        dispatch({
            type: PURCHASE_DATA_SUCCESS,
            purchases
        });
        dispatch(purchaseFetchHasError(false));
    };
}

export function purchaseFetchHasError(bool) {
    return {
        type: PURCHASE_FETCH_HAS_ERROR,
        hasError: bool
    };
}

export function purchasesIsLoading(bool) {
    return {
        type: PURCHASE_FETCH_DATA_IS_LOADING,
        isLoading: bool
    };
}