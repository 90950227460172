import React, { Component } from 'react';
import { Card, CardHeader, CardBody, CardTitle, CardSubtitle } from 'reactstrap';
import Avatar from 'react-avatar';
import "../styles/ProgressCard.css";
import LevelBar from '../../Common/components/LevelBar';
import Icon from '../../Common/components/Icon';
import { HIGHEST_DEFINED_LEVEL_POINTS, 
         POINTS_TO_GAIN_LEVEL,
         HIGHEST_DEFINED_LEVEL,
         LEVEL_SUMMARY,
         calculate as levelCalculation
    } from '../../util/levelInformation';

class ProgressCard extends Component {
    render() {
        const { user } = this.props;
        const userPoints = isNaN(user.points) ? 0 : user.points;
        const levelBarTotalPoints = HIGHEST_DEFINED_LEVEL_POINTS > userPoints ? HIGHEST_DEFINED_LEVEL_POINTS : userPoints + POINTS_TO_GAIN_LEVEL;
        const completablePoints = (Math.floor(levelBarTotalPoints / POINTS_TO_GAIN_LEVEL)) * POINTS_TO_GAIN_LEVEL
        const {userLevel, pointsToNextLevel} = levelCalculation(userPoints);
        const maxBarLevel = HIGHEST_DEFINED_LEVEL > userLevel ? HIGHEST_DEFINED_LEVEL : userLevel + 1;
        const closestReward = this.props.levelsWithRewards.find((level) => level > userLevel)

        let listOfLevels = [...LEVEL_SUMMARY];

        for(let i = HIGHEST_DEFINED_LEVEL; i < maxBarLevel; i++){
            listOfLevels.push(listOfLevels[i] + POINTS_TO_GAIN_LEVEL);
        }
        listOfLevels = listOfLevels.slice(-6);
        
        return (
            <Card className="paper-light">
                <CardHeader className="d-flex flex-row align-items-center">
                    <Avatar color="var(--osu-gray-medium)" name={user.displayName} size={40} round={true} />
                    <div className="flex-column ml-2">
                        <CardTitle>{`Welcome to Cybersecurity for You, ${this.props.user.displayName}`}</CardTitle>
                        <CardSubtitle className="mb-2">Complete activities, finish achievements, gain levels, and get rewarded!</CardSubtitle>
                        <CardSubtitle className="mb-2">Check back regularly for updates.</CardSubtitle>
                    </div>
                </CardHeader>
                <CardBody>
                    <CardTitle>Your Progress</CardTitle>
                    <CardSubtitle>Level up with activities and achievements!</CardSubtitle>
                    <LevelBar
                        currentPoints={userPoints}
                        userLevel={userLevel}
                        maxLevel={maxBarLevel}
                        maxPoints={levelBarTotalPoints}
                        startingValue={"Start"}
                        stops={HIGHEST_DEFINED_LEVEL}
                        levelsWithRewards={this.props.levelsWithRewards}
                        levelPoints={listOfLevels}
                    />
                </CardBody>
                <div className="level-card-footer">
                    {FooterDataCard("fa-star", "--osu-gold", "points progress", `${pointsToNextLevel} Points to Level ${userLevel + 1}`)}
                    {FooterDataCard("fa-gift", "--osu-blue", "rewards progress", userPoints < completablePoints && closestReward ?  `Next reward at level ${closestReward}` : "Stay tuned for more rewards!")}
                </div>
            </Card>);
    }
}

const FooterDataCard = (badgeIcon, colorRef, title, subtitle) =>
    <CardHeader className="level-card-tile d-flex flex-row">
        <Icon className="mr-2" type={badgeIcon} circle color={colorRef} />
        <div className="flex-column">
            <div className="subheading text-uppercase">{title}</div>
            <div className="text-capitalize">{subtitle}</div>
        </div>
    </CardHeader>

export default ProgressCard;