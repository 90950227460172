import { withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { quizSubmit, quizSubmitClear } from "../../actions-index";
import Assessment from '../components/Assessment';

const mapStateToProps = (state, ownProps) => {
    return {
        user: state.user,
        quiz: state.quiz,
        quizHasError: state.quizHasError,
        quizResult: state.quizResult,
        ownProps
    };
  };
  
  const mapDispatchToProps = (dispatch) => {
    return {
        submitTheQuiz: (quizAnswers) =>  dispatch(quizSubmit(quizAnswers)),
        clearQuizSubmission: () => dispatch(quizSubmitClear())
    };
  };
  
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(reduxForm({ form: 'assesmentForm' })(Assessment)));