import React, { Component } from 'react';
import Article from './Article';
import Feature from './Feature';
import Achievement from './Achievement';
import { withRouter } from "react-router-dom";
import AssessmentContainer from '../containers/Assessment';
import Error from '../../Common/components/Error';
import Loader from '../../Common/components/Loader';
import { isEmpty } from 'lodash';

const COMPLETE_STATUS = "COMPLETE";

const CONTENT_TYPES = {
    article: Article,
    featured: Feature,
    achievement: Achievement
};

class Content extends Component {

    componentDidMount() {
        this.props.clearContentState();
        this.props.fetchCategories();
        this.props.fetchContent(this.props.match.params.id);
        if (!this.props.contentList || this.props.contentList.length === 0) {
            this.props.fetchCardData();
        }
    }

    componentDidUpdate(prevProps) {
        if (JSON.stringify(this.props.content) !== JSON.stringify(prevProps.content) && this.props.content && this.props.content.quizId) { 
            this.props.fetchTheQuiz(this.props.content.quizId);
        }
    }
    
    componentWillReceiveProps(newProps) {
        if (newProps.match.params.id !== this.props.match.params.id) {
            this.props.clearContentState();
            this.props.fetchContent(newProps.match.params.id);
        }
        return true;

    }

    filterByAchievementType(contentItem) {
        return contentItem.type === "achievement" && contentItem.published === 'Y';
    }

    filterByComplete(contentItem) {
        if (!contentItem.percent || contentItem.percent < 100) {
            return true
        } return false
    }

    render() {
        if ([this.props.userHasError, this.props.categoriesHasError, this.props.contentHasError].some(Boolean)) {
            return (
                <div className="container row h-100 justify-content-center align-items-center m-5">
                    <Error errorMessage={this.props.userHasError ? "Error has occured. Please refresh the page." : ""} />
                </div>
            )
        }

        if (this.props && this.props.content && !isEmpty(this.props.user) && !this.props.isContentLoading) {

            //setup any related content
            if (this.props.content.relatedContent) {
                const contentWithHandler = this.props.content.relatedContent.map(content => {
                    content.callback = this.props.handleContentCardClick
                    const userContentCompleted = this.props.user.content
                    if (content.category && !content.category.categoryId) {
                        content.category = this.props.categories[content.category];
                    }
                    content.completed = userContentCompleted ? !!userContentCompleted[content.id] : false;
                    return content;
                });
                this.props.content.relatedContent = contentWithHandler;
            }

            if (this.props.content.achievements) {
                this.props.content.achievements.map(achievement => {
                    if (achievement.relatedContent) {
                        const achievementContentWithHandler = achievement.relatedContent.map(achievementContentItem => {
                            achievementContentItem.callback = this.props.handleContentCardClick;
                            const achievementContentCompleted = this.props.user.content;
                            achievementContentItem.completed = achievementContentCompleted ? !!achievementContentCompleted[achievementContentItem.id] : false;
                            return achievementContentItem;
                        })
                        achievement.relatedContent = achievementContentWithHandler
                    }
                    return achievement;
                })
            }

            let availableAchievements = [];
            if (this.props.contentList.length > 0) {
                availableAchievements = this.props.contentList.filter(this.filterByAchievementType)
                availableAchievements.forEach(achievement => {
                    achievement.callback = this.props.handleContentCardClick;
                    const achievementCompleted = this.props.user.achievements;
                    achievement.completed = achievementCompleted ? !!achievement[achievement.id] : false;
                    achievement.percent = this.props.user.achievements[achievement.id] && this.props.user.achievements[achievement.id].progress;
                });
            }

            let completed = false;
            const contentId = this.props.content.id
            const userContent = this.props.user.content;
            const userAchievements = this.props.user.achievements;
            if ((userContent && userContent[contentId] && userContent[contentId].status === COMPLETE_STATUS) ||
                (userAchievements && userAchievements[contentId] && userAchievements[contentId].status === COMPLETE_STATUS)) {
                completed = true;
            }


            const TagName = CONTENT_TYPES[this.props.content.type];
            return (

                <div>
                    <TagName {...this.props} availableAchievements={availableAchievements} content={this.props.content}>
                        <AssessmentContainer completed={completed} quizId={this.props.content.quizId} content={this.props.content} />
                    </TagName>

                </div>
            );
        } else {
            return (<Loader/>);
        }
    }
}

export default withRouter(Content);