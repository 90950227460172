import React, { Component } from 'react';
import { CardDeck } from 'reactstrap';
import ContentCard from '../../Common/components/ContentCard';
import RewardCard from '../../Common/components/RewardCard';
import * as _ from 'lodash';
import "../styles/CardGrid.css";
import Icon from '../../Common/components/Icon';

class CardGrid extends Component {

    constructor(props) {
        super(props);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.state = { input: "" };
    }

    handleInputChange(evt) {
        this.setState({ input: evt.target.value })
    }

    render() {
        let filteredContent = _.filter(this.props.content, (content) => {
            if (content.type === 'achievement') {
                return content.contentItemsRequired && content.contentItemsRequired.length > 0
            } else {
                return content
            }
        })

        if (this.props.content.length > 0 && this.state.input) {
            filteredContent = _.filter(this.props.content, (obj) => {
                if (obj.title.toLowerCase().indexOf(this.state.input.toLowerCase()) >= 0) {
                    return obj;
                }
            });
            if(this.searchbarAnnouncements) {
                this.searchbarAnnouncements.textContent = `Your search returned ${filteredContent.length > 0 ? filteredContent.length : 'no'} results.`;
            }
        } else {
            if(this.searchbarAnnouncements) {
                this.searchbarAnnouncements.textContent = `Displaying all ${this.props.contentLabel}`;
            }
        }
        const completedContent = filteredContent.filter((content) => content.completed)
        const incompleteContent = filteredContent.filter((content) => !content.completed)

        filteredContent.sort(function (a, b) {
            if (a.completed === b.completed) {
                return 0;
            } else if (a.completed) {
                return 1;
            } else {
                return -1;
            }
        });

        if (this.props.category) {
            return (
                <CardDeck className="mr-0 ml-0 d-flex flex-column">
                    {this.props.searchable && (
                        <div className={"form-group mt-3 mb-3 d-flex flex-grow-1"} role="search">
                            <div className="input-group">
                                <div className="input-group-prepend" aria-hidden="true">
                                    <span className="input-group-text">
                                        <Icon type="search" />
                                    </span>
                                </div>
                                <label htmlFor="searchbar" className="sr-only">Search {this.props.contentLabel}</label>
                                <input id="searchbar" className={"form-control"} placeholder="Search" type="text" aria-describedby="searchbar-helptext" onChange={this.handleInputChange} />
                                <p id="searchbar-helptext" className="sr-only">Dynamically filters the {this.props.contentLabel} as you type your search.</p>
                                <div aria-live="polite" className="sr-only" ref={el => (this.searchbarAnnouncements = el)} />
                            </div>
                        </div>
                    )}
                    {this.props.separateCardGridByCompletion && completedContent.length > 0 ?                    
                        <div>
                            {React.createElement(this.props.groupHeadingDesignation, {className: "sr-only"}, "Incomplete Content")}
                            {displayCardGrid(incompleteContent, this.props.inner, this.props.headingDesignation)}
                            {React.createElement(this.props.groupHeadingDesignation, {className: "ml-3 mt-3 mb-2"}, "Completed Content")}
                            {displayCardGrid(completedContent, this.props.inner, this.props.headingDesignation)}
                        </div>
                        : <div>{displayCardGrid(filteredContent, this.props.inner, this.props.groupHeadingDesignation)}</div>
                    }

                    {filteredContent.length <= 0 &&
                        <div className="row">
                            <div className="col-12">
                                <p className="text-left pl-3">No results were found.</p>
                            </div>
                        </div>
                    }
                </CardDeck>
            );
        } else {
            return (
                <div>
                    <p>
                        Please Select a Category!
                    </p>
                </div>
            );
        }
    }
}

const displayCardGrid = (contentToDisplay, inner, headingDesignation) => {
    if (contentToDisplay.length > 0) {
        return <div className="row">
            {contentToDisplay.map((content) =>
                content.type !== 'reward' ? (
                    <ContentCard 
                        className={"inner-card"}
                        inner={inner}
                        key={content.id}
                        data={content}
                        handleClick={content.callback}
                        headingDesignation={headingDesignation} />
                ) : (
                        <RewardCard 
                            className={"inner-card"}    
                            inner={inner}
                            key={content.rewardId}
                            data={content}
                            addRewardToCart={content.addToCartClicked}
                            removeRewardFromCart={content.removeFromCartClicked}
                        />
                    )
            )
            }
        </div>
    }
}

CardGrid.defaultProps = {
    searchable: true,
    groupHeadingDesignation: "h3",
    headingDesignation:"h4"
};

export default CardGrid;