import { withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import { categoriesFetchData, clearContentState, contentAllCardData, contentFetchData, quizFetchData } from "../../actions-index";
import Content from '../components';

const mapStateToProps = (state, ownProps) => {
    return {
        user: state.user,
        
        content: state.content,
        contentHasError: state.contentHasError,

        contentList: state.contentList,
        // availableAchievements: state.availableAchievements,

        categories: state.categories,
        categoriesHasError: state.categoriesHaveError,
        categoriesAreLoading: state.categoriesAreLoading,

        isContentLoading: [state.contentIsLoading, state.contentListIsLoading, state.categoriesAreLoading, state.userIsLoading, state.quizIsLoading].some(Boolean),

        ownProps
    };
  };
  
  const mapDispatchToProps = (dispatch) => {
    return {
        fetchTheQuiz: (id) => dispatch(quizFetchData(id)),
        fetchCardData: () => dispatch(contentAllCardData()),
        fetchContent: (id) => dispatch(contentFetchData(id)),
        fetchCategories: () => dispatch(categoriesFetchData()),
        clearContentState: () => dispatch(clearContentState()),
    };
  };
  
  export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Content));