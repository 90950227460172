import React, { Component } from 'react';
import { Row, Col, Card, CardBody, CardHeader, CardText } from 'reactstrap';
import LevelBar from '../../Common/components/LevelBar';
import CardGrid from '../../Common/components/CardGrid';
import { withRouter } from "react-router-dom";
import * as _ from 'lodash';
import "../styles/Achievement.css";
import 'react-quill/dist/quill.snow.css';
import Icon from '../../Common/components/Icon';

class Achievement extends Component {
    render() {
        let percentage = 0;
        let timeLeft = 0;
        let totalTime = 0;
        let activitiesCompleted = 0;
        let totalActivities = 0
        if (this.props.content && this.props.content.relatedContent && this.props.content.relatedContent.length > 0) {
            totalTime = _.sumBy(this.props.content.relatedContent, 'estMinForComplete');
            timeLeft = _.sumBy(this.props.content.relatedContent, (obj) => {
                if (!obj.completed) { return obj.estMinForComplete }
            });
            let completionRatio = (_.filter(this.props.content.relatedContent, { completed: true }).length / this.props.content.relatedContent.length);
            percentage = Math.floor(completionRatio * 100);
            activitiesCompleted = Math.floor((completionRatio) * this.props.content.relatedContent.length);
            totalActivities = this.props.content.relatedContent.length
            for(let content of this.props.content.relatedContent){
                content.category = ((Array.isArray(content.categories) && content.categories.length > 0) ? this.props.categories[content.categories[0]] : null);
            }
        }
        return (
            <div>
                <h1 className="d-none">Achievement Information</h1>
                <Row>
                    <Col xs="12">
                        <Card className="paper-light">
                            <CardHeader className="d-flex flex-row align-items-center">
                                <Icon type={this.props.content.achievementBadge || "question"} color={this.props.content.backgroundColor} circle className="mr-2" />
                                <h2 className="ml-2 mb-0 achievement-summary-header">{this.props.content.title}</h2>
                            </CardHeader>
                            <CardBody>
                                <p className="subheading achievement-info-section">
                                    <span className="mr-4 mb-2"><Icon type="clock-o" /> {totalTime} Minutes</span>
                                    <span className="mr-4 mb-2"><Icon type="check-circle" /> {this.props.content.contentItemsRequired.length} Activities</span>
                                    {/* <span><Icon type="star mb-2" /> {this.props.content.points} Points</span> */}
                                    <span className="subheading achievement-heading">ACHIEVEMENT</span>
                                </p>
                                <p className="achievement-body pt-4 mt-4 mb-4">{this.props.content.subText}</p>
                                <LevelBar
                                    maxPoints={100}
                                    maxLevel={0}
                                    userLevel={0}
                                    currentPoints={percentage}
                                    stops={0}
                                />
                                <p className="subheading d-flex mt-2 mb-2">
                                    <CardText className="subheading">{`${activitiesCompleted} of ${totalActivities} ${totalActivities !== 1 ? `Activities` : `Activity`} Completed`}</CardText>
                                    <CardText></CardText>
                                    <span className="ml-auto subheading">{`${timeLeft ? timeLeft : 0} minutes remaining`}</span>
                                </p>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                <CardGrid inner={true} category=" " contentLabel="Activities" content={this.props.content.relatedContent} />
            </div>
        );
    }
}

export default withRouter(Achievement);