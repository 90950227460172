import { API } from "aws-amplify";
import { USER_HAS_ERROR, USER_NO_ERROR, USER_IS_LOADING, USER_FETCH_DATA_SUCCESS, CONTENT_COMPLETE_POST_SUCCESS,
    FEATURES_HAVE_ERROR, FEATURES_ARE_LOADING, FEATURES_FETCH_DATA_SUCCESS, FEATURE_HAS_ERROR, FEATURE_IS_LOADING,
    FEATURE_SELECTED } from "../actions-index";

export function userHasError(bool) {
    console.log("user error", bool);
    return {
        type: bool ? USER_HAS_ERROR : USER_NO_ERROR,
        hasError: bool
    };
}

export function userIsLoading(bool) {
    console.log("user is loading");

    return {
        type: USER_IS_LOADING,
        isLoading: bool
    };
}

export function userFetchDataSuccess(user) {
    console.log("user fetch data success", user);

    return (dispatch) => {
        dispatch(userHasError(false));
        dispatch({
            type: USER_FETCH_DATA_SUCCESS,
            user
        });
    };
}

export function userFetchData() {
    console.log("user fetch data");
    return (dispatch) => {
        dispatch(userIsLoading(true));

        API.get(process.env.REACT_APP_API_NAME, "/user")
            .then((response) => {
                response.data.showWelcome = true;
                // console.log("********Inside achievement check:", response.data.achievements);
                if (typeof response.data.achievements !== 'undefined') {
                    //console.log("***Inside achievement check:", response.data.achievements, Object.keys(response.data.achievements).length);
                    for (var achievementKey in response.data.achievements) {
                        // console.log("achievementKey->", achievementKey);
                        if (response.data.achievements[achievementKey]) {
                            var achievement = response.data.achievements[achievementKey];
                            //console.log(achievementKey + " -> " + response.data.achievements[achievementKey].status);
                            if (achievement.status === "COMPLETE") {
                                //TODO: Need to change to false.
                                response.data.showWelcome = true;
                                break;
                            }
                        }
                    }
                }

                dispatch(userIsLoading(false));
                return response;
            })
            .then((response) => {
                //console.log("USER-ResponseData->",response.data);
                // console.log("***************user:",response.data);
                dispatch(userFetchDataSuccess(response.data))
            })
            .catch(() => {
                dispatch(userHasError(true));
                dispatch(userIsLoading(false));
            });
    };
}

export function userContentCompletePostSuccess(user) {
    console.log("USER content complete POST success", user);

    return {
        type: CONTENT_COMPLETE_POST_SUCCESS,
        user
    };
}

export function featuresHaveError(bool) {
    return {
        type: FEATURES_HAVE_ERROR,
        hasError: bool
    };
}

export function featuresAreLoading(bool) {    
    return {
        type: FEATURES_ARE_LOADING,
        isLoading: bool
    };
}

export function featuresFetchDataSuccess(features) {
    return {
        type: FEATURES_FETCH_DATA_SUCCESS,
        features
    };
}

export function featuresFetchData() {
    return (dispatch) => {
        dispatch(featuresAreLoading(true));

        API.get(process.env.REACT_APP_API_NAME, "/features")
            .then((response) => {
                dispatch(featuresAreLoading(false));
                dispatch(featuresFetchDataSuccess(response.data))
            })
            .catch(() => dispatch(featuresHaveError(true)));
    };
}

export function featureHasError(bool) {
    return {
        type: FEATURE_HAS_ERROR,
        hasError: bool
    };
}

export function featureIsLoading(bool) {    
    return {
        type: FEATURE_IS_LOADING,
        isLoading: bool
    };
}

export function featureFetchDataSuccess(feature) {
    return {
        type: FEATURE_SELECTED,
        feature
    };
}

export function featureFetchData(id) {
    return (dispatch) => {
        dispatch(featureIsLoading(true));

        API.get(process.env.REACT_APP_API_NAME, `/content/${id}`)
            .then((response) => {
                dispatch(featureIsLoading(false));
                dispatch(featureFetchDataSuccess(response.data))
            })
            .catch(() => dispatch(featureHasError(true)));
    };
}