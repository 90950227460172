import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import {
    Collapse,
    Navbar,
    NavbarBrand,
    NavbarToggler,
    Nav,
    NavItem,
    NavLink,
} from 'reactstrap';
import Avatar from 'react-avatar';
import '../styles/Header.css';
import levelInformation from '../../util/levelInformation';
import Icon from '../../Common/components/Icon';

class Header extends Component {

    constructor(props) {
        super(props);
        this.toggle = this.toggle.bind(this);
        this.state = {
            isOpen: false
        };
    }

    componentDidMount() {
        this.updateResponsiveness();
        window.addEventListener("resize", this.updateResponsiveness.bind(this));
    }

    componentDidUpdate(prevProps) {
        if(prevProps.location.pathname !== this.props.location.pathname) {
            this.setState({ isOpen: false });
        }
    }

    componentWillUnmount() {
        this.isCancelled = true;
    }

    updateResponsiveness() {
        !this.isCancelled && this.setState({ isXs: window.innerWidth < 576 })
    }

    toggle() {
        !this.isCancelled && this.setState({
            isOpen: !this.state.isOpen
        });
    }

    onNavLinkClick = () => {
        this.setState({ isOpen: false });
    }

    render() {
        const activeState = this.props.location.pathname;

        const {currentPoints, userLevel} = levelInformation.calculate(this.props.user.points);

        return (
            <div className={`sticky ${this.props.className}`}>
                <div role="navigation" className="row progressHeader no-row-margins">
                    <div className="pl-0 col-3 col-sm-4 col-md-6 col-lg-8">
                        <Navbar className="pl-0 menu-header" expand="md" light>
                            <NavbarToggler onClick={this.toggle} />
                            <Collapse isOpen={this.state.isOpen} navbar>
                                <Nav navbar className="d-flex flex-wrap">
                                    <NavbarBrand className="d-none d-lg-block" tag={Link} to="/" {...(activeState === "/" ? { "aria-current": "page" } : {})}>
                                        <img src="/images/cybersecurity-for-you.svg" alt="Cybersecurity For You Logo" width="150" />
                                    </NavbarBrand>
                                    <NavItem className="d-lg-none">
                                        <NavLink tag={Link} to="/" {...(activeState === "/" ? { "aria-current": "page", className: "active-header" } : { className: "inactive-header" })} onClick={this.onNavLinkClick}>Home</NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink tag={Link} to="/achievements" {...(activeState.indexOf("/achievements") === 0 ? { "aria-current": "page", className: "active-header" } : { className: "inactive-header" })} onClick={this.onNavLinkClick}>Achievements</NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink tag={Link} to="/activities" {...(activeState.indexOf("/activities") === 0 ? { "aria-current": "page", className: "active-header" } : { className: "inactive-header" })} onClick={this.onNavLinkClick}>Activities</NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink tag={Link} to="/rewards" {...(activeState.indexOf("/rewards") === 0 ? { "aria-current": "page", className: "active-header" } : { className: "inactive-header" })} onClick={this.onNavLinkClick}>Rewards</NavLink>
                                    </NavItem>
                                    {/* <NavItem>
                                        <NavLink tag={Link} to="/leaderboard" {...(activeState.indexOf("/leaderboard") === 0 ? { "aria-current": "page", className: "active-header" } : { className: "inactive-header" })} onClick={this.onNavLinkClick}>Leaderboard</NavLink>
                                    </NavItem> */}
                                    <NavItem>
                                        <NavLink tag={Link} to="/profile" {...(activeState.indexOf("/profile") === 0 ? { "aria-current": "page", className: "active-header" } : { className: "inactive-header" })} onClick={this.onNavLinkClick}>Profile</NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink style={{ color: 'var(--osu-red-dark)' }} href="https://cybersecurity.osu.edu" target="_blank" onClick={this.onNavLinkClick}>cybersecurity.osu.edu</NavLink>
                                    </NavItem>
                                    <NavItem className="d-sm-none">
                                        <NavLink tag={Link} to="" onClick={() => this.props.signOut()} className="osu-gray-link" style={{color: "rgba(0, 0, 0, 0.6)"}}>LOGOUT</NavLink>
                                    </NavItem>
                                </Nav>
                            </Collapse>
                        </Navbar>
                    </div>
                    { (this.props.location && this.props.location.pathname !== '/logout') &&
                        <div className="pr-0 mt-2 col-9 col-sm-8 col-md-6 col-lg-4 media d-flex">
                            {!this.props.cartCount ||
                                <Link aria-hidden className="ml-auto mr-1 osu-red-link" to={"/cart"}>
                                    <Icon className="mt-3 mr-2" size="lg" type="shopping-cart" />
                                    Cart ({this.props.cartCount})
                                </Link>
                            }
                            <Link className={`${!this.props.cartCount ? 'ml-auto': 'ml-2'} mt-1` } to={"/profile"}>
                                <Avatar color="var(--osu-gray-medium)" name={this.props.user.displayName} size={40} round={true} />
                            </Link>
                            <div className="ml-2 d-flex flex-column">
                                {
                                    this.props.user && this.props.user.firstName &&
                                    <div className="text-left subheading text-uppercase">
                                        {userLevel < 1 ? 'Entry ' : ''}Level{userLevel >= 1 ? ` ${userLevel}` : ''}
                                    </div>
                                }
                                {
                                    this.props.user && this.props.user.firstName &&
                                    <div className="text-right">
                                        {`${currentPoints} Points`}
                                    </div>
                                }
                            </div>
                            {/* <Link className={`${!this.props.cartCount ? 'ml-auto': 'ml-2'} mt-1` } to={"/logout"}> */}
                                {/* <NavLink tag={Link} to="/profile" className={activeState.indexOf("/profile") === 0 ? "active-header" : ""}>LOGOUT</NavLink> */}
                            {/* </Link> */}
                            <Link tag={Link} to="" style={{color: "rgba(0, 0, 0, 0.6)"}} className="d-none d-sm-block ml-3 mt-2 osu-gray-link" onClick={() => this.props.signOut()}>
                                LOGOUT
                            </Link>
                        </div>
                    }
                </div>
            </div>
        );
    }
}

export default Header;