import React from 'react';
import FeaturedContentCard from './FeaturedContentCard';

export const FeaturedContentCardGrid = (props) => {
    const { featuredContentData, points, incompleteAchievement, unstartedContentItem, userRewardEligibility, headingDesignation = "h4" } = props
    return [
        featuredContentData && <FeaturedContentCard
            key={`Featured Content Item ${featuredContentData.id}`}
            isFeatured={featuredContentData.callback ? false : true}
            inner
            data={featuredContentData}
            handleClick={featuredContentData.callback ? featuredContentData.callback : null}
            index={0}
            headingDesignation={headingDesignation} />,
        renderSecondFeaturedCard(points, incompleteAchievement, unstartedContentItem, userRewardEligibility, headingDesignation)
    ].slice(0, 2)
}

const renderSecondFeaturedCard = (points, incompleteAchievement, unstartedContentItem, userIsEligibleForReward = false, headingDesignation) => {
    const possibleScenarios = {
        CASE_1: {
            condition: userIsEligibleForReward,
            data: {
                altText: "Redeem your Reward!",
                subText: "Congratulations! You have earned enough points to redeem a reward in the store.",
                icon: "gift",
                isFeatured: true,
                dataSuccess: true,
                action: "rewards"
            }
        },
        CASE_2: {
            //Change heading to be more finish where you left off {...obj, alttext=""}
            condition: incompleteAchievement !== null && typeof incompleteAchievement === 'object',
            data: incompleteAchievement
        },
        CASE_3: {
            // condition: (points > 0),
            condition: false, //forcing skip to hide leaderboard featured card
            data: {
                altText: "Explore the Leaderboard!",
                subText: "Check out all the badges people have earned through activities and achievements!",
                icon: "trophy",
                isFeatured: true,
                dataSuccess: true,
                action: "leaderboard"
            }
        },
        CASE_4: {
            condition: (unstartedContentItem !== null && typeof unstartedContentItem === 'object'),
            data: unstartedContentItem
        }
        //Default to profile
    }

    if (possibleScenarios.CASE_1.condition) {
        return <FeaturedContentCard
            key={`Featured Content Item 2`}
            inner
            index={1}
            data={possibleScenarios.CASE_1.data}
            headingDesignation={headingDesignation}
        />
    } else if (possibleScenarios.CASE_2.condition) {
        return <FeaturedContentCard
            key={`Featured Content Item 2`}
            isFeatured={false}
            inner
            data={{...possibleScenarios.CASE_2.data, subheading: incompleteAchievement.title, title: "Finish your achievement!"}}
            handleClick={possibleScenarios.CASE_2.data.callback}
            index={1}
            headingDesignation={headingDesignation}
        />
    } else if (possibleScenarios.CASE_3.condition) { //will be false due to forced condition in possible senarios
        return <FeaturedContentCard
            key={`Featured Content Item 2`}
            inner
            index={1}
            data={possibleScenarios.CASE_3.data}
            headingDesignation={headingDesignation}
        />
    } else if (possibleScenarios.CASE_4.condition) {
        return <FeaturedContentCard
            key={`Featured Content Item 2`}
            isFeatured={false}
            inner
            data={possibleScenarios.CASE_4.data}
            handleClick={possibleScenarios.CASE_4.data.callback}
            index={1}
            headingDesignation={headingDesignation}
        />
    } else {
        return <FeaturedContentCard
            key={`Featured Content Item 2`}
            isFeatured
            inner
            data={{
                altText: "Explore your Profile!",
                subText: "See all your badges. Check out the activities and achievements you have completed!",
                icon: "user",
                isFeatured: true,
                dataSuccess: true,
                action: "profile"
            }}
            index={1}
            headingDesignation={headingDesignation}
        />
    }
}