import {withRouter} from "react-router-dom";
import {connect} from 'react-redux';
import Purchases from "../components";
import { purchasesFetchData, redeemedRewardsFetchData, redeemedRewardsFetchDataSuccess, userFetchData } from "../../actions-index";

const mapStateToProps = (state) => {
    return {
        user: state.user,
        cart: state.cart,
        rewards: state.redeemedRewards,
        rewardsIsLoading: state.rewardsIsLoading,
        rewardsHasError: state.rewardsHasError,
        purchases: state.purchases,
        purchasesIsLoading: state.purchasesIsLoading,
        purchasesHasError: state.purchasesHasError,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        purchasesFetchData: () => dispatch(purchasesFetchData()),
        fetchUser: () => dispatch(userFetchData()),
        fetchRewards: (ids) => dispatch(redeemedRewardsFetchData(ids)),
        clearRewards: () => dispatch(redeemedRewardsFetchDataSuccess([]))
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Purchases));