import React, { Component } from 'react';
import CardGrid from '../../Common/components/CardGrid';
import PageHeader from './PageHeader';
import "../styles/ContentHome.css";
import Error from "../../Common/components/Error";
import * as _ from "lodash";
import Loader from '../../Common/components/Loader';

class ContentHome extends Component {

    constructor(props) {
        super(props);
        this.state = {
            setInitialFocus: false
        };
	}

    componentDidMount() {
        this.props.fetchCardData();
        this.props.fetchCategories();
    }

    componentDidUpdate(prevProps) {
        if(this.pageHeading && (this.state.setInitialFocus === false || prevProps.contentLabel !== this.props.contentLabel)) {
            this.pageHeading.focus();
            this.setState({ setInitialFocus: true });
        }
    }

    render() {        
        if ([this.props.userHasError, this.props.categoriesHasError, this.props.contentHasError].some(Boolean)) {
            return (
                <div className="container-fluid row h-100 justify-content-center align-items-center m-5">
                    <Error errorMessage={this.props.userHasError ? "Error has occured. Please refresh the page." : ""} />
                </div>
            )
        }
        if(!this.props.isContentHomeLoading) {
            let contentWithHandler = _.sortBy(this.props.content.filter(content => {
                if (content.type === this.props.contentType) {
                    content.callback = this.props.handleContentCardClick;
                    if (this.props.user && this.props.user.content && this.props.user.achievements) {
                        content.completed = !!this.props.user.content[content.id] ||
                            (!!this.props.user.achievements[content.id] && this.props.user.achievements[content.id].status === "COMPLETE");
                        content.percent = this.props.user.achievements[content.id] && this.props.user.achievements[content.id].progress; //returns progress
                    }
                    return content;
                } else {
                    return null;
                }
            }), ['completed', 'sortPriority', 'title']);

            //don't show completed content


            return (
                <div className="d-flex flex-column">
                    <PageHeader title={this.props.contentLabel} setRef={el => (this.pageHeading = el)} />
                    <CardGrid category={" "} separateCardGridByCompletion contentLabel={this.props.contentLabel} content={contentWithHandler} />
                </div>
            );
        } else {
            return (<Loader />);
        }
    }

}

export default ContentHome;