import React, { Component } from 'react';
import { Card, CardText, CardImg, Button } from 'reactstrap';
import "../styles/RewardCard.css";
import PropTypes from 'prop-types';

const IN_CART = "IN_CART";
const AVAILABLE = "AVAILABLE";
const UNAVAILABLE_F0R_CART = "UNAVAILABLE_F0R_CART";
const EMPTY_SPACE = <span aria-hidden={true}>&nbsp;</span>

class RewardCard extends Component {
	constructor(props){
		super(props);
		this.handleCategoryChange = this.handleCategoryChange.bind(this);
		this.renderCategories = this.renderCategories.bind(this);
		
		this.state = {
			category: (props && props.data && props.data.categories && props.data.categories[0]) || null
		}
	}

	renderCategories(data){
		let {categories, status, title} = data;
		if(status === AVAILABLE || status === IN_CART || status === UNAVAILABLE_F0R_CART){
			return <select onLoad={this.handleCategoryChange} onChange={this.handleCategoryChange} aria-label="category" disabled={status === IN_CART || status === UNAVAILABLE_F0R_CART}>
						{categories.map(cat => <option key={cat + '-' + title} value={cat}>{cat}</option>)}
					</select>
		} else {
			return EMPTY_SPACE;
		}
		
	}

	handleCategoryChange(event){
		const category = event.target.value;
		this.setState(() => {
			return {category};
		})
	}

	render() {
		const props = this.props;

		return <div className={`${props.className} ` + (props.noCols ? "" : props.inner ? "col-xs-12 col-md-6 col-xl-4" : "")}>
			<Card className="paper mb-4">
				<div className="rewards-image-wrapper">
					<CardImg top width="100%" src={props.data.imageUrl} alt={'picture of ' + props.data.title} />
					<div className="rewards-overlay">
						<CardText>{props.data.title}</CardText>
					</div>
					<div>
						
					</div>
					<div className="mb-3 ml-3">
						<CardText>
							{ props.data.categories ? this.renderCategories(props.data) : EMPTY_SPACE }
						</CardText>
					</div>
				</div>
				{switchButtonBasedOnCart(props, this.state.category)}
			</Card>
		</div>
	}
}

function renderButton(title, onButtonClick = null, disabled = false) {
	return <div className="action-sheet"><Button disabled={disabled} onClick={onButtonClick} className="m-3 osu-red-btn align-self-start">{title}</Button></div>
}

function switchButtonBasedOnCart(props, category = null) {
	switch (props.data.status) {
		case IN_CART:
			return renderButton("Remove from Cart", props.removeRewardFromCart);
		case AVAILABLE:
			return renderButton("Add To Cart", () => {
				return props.addRewardToCart(category);
			});
		case UNAVAILABLE_F0R_CART:
			return renderButton("Add To Cart", props.addRewardToCart, true);
		default:
			return null;
	}
}

RewardCard.propTypes = {
	removeRewardFromCart: PropTypes.func.isRequired,
	addRewardToCart: PropTypes.func.isRequired,
	data: PropTypes.object.isRequired
}

export default RewardCard;