import { LEADERBOARD_DATA_HAS_ERROR, LEADERBOARD_DATA_NO_ERROR, LEADERBOARD_DATA_IS_LOADING,
    LEADERBOARD_DATA_SUCCESS, LEADERBOARD_USER_DATA_HAS_ERROR, LEADERBOARD_USER_DATA_NO_ERROR,
    LEADERBOARD_USER_DATA_IS_LOADING, LEADERBOARD_USER_DATA_SUCCESS } from "../actions-index";

export function leaderboardHasError(state = false, action) {
    switch (action.type) {
        case LEADERBOARD_DATA_HAS_ERROR:
        case LEADERBOARD_DATA_NO_ERROR:
            return action.hasError;

        default:
            return state;
    }
}

export function leaderboardIsLoading(state = false, action) {
    switch (action.type) {
        case LEADERBOARD_DATA_IS_LOADING:
            return action.isLoading;

        default:
            return state;
    }
}

export function leaderboard(state = [], action) {
    switch (action.type) {
        case LEADERBOARD_DATA_SUCCESS:
            return action.leaderboard;
        default:
            return state;
    }
}

export function leaderboardUserHasError(state = false, action) {
    switch (action.type) {
        case LEADERBOARD_USER_DATA_HAS_ERROR:
        case LEADERBOARD_USER_DATA_NO_ERROR:
            return action.hasError;

        default:
            return state;
    }
}

export function leaderboardUserIsLoading(state = false, action) {
    switch (action.type) {
        case LEADERBOARD_USER_DATA_IS_LOADING:
            return action.isLoading;

        default:
            return state;
    }
}

export function leaderboardUser(state = [], action) {
    switch (action.type) {
        case LEADERBOARD_USER_DATA_SUCCESS:
            return action.leaderboardUser;

        default:
            return state;
    }
}