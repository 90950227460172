import { PURCHASE_SUBMIT_SUCCESS, PURCHASE_DATA_SUCCESS, PURCHASE_SUBMIT_HAS_ERROR,
    PURCHASE_FETCH_HAS_ERROR, PURCHASE_FETCH_DATA_IS_LOADING } from "../actions-index";

export function purchases(state = [], action) {
    switch (action.type) {
        case PURCHASE_SUBMIT_SUCCESS:
            return [...state, ...action.purchases.results.map( (purchase) => Object.assign({}, purchase))];
        case PURCHASE_DATA_SUCCESS:
            return action.purchases;
        default:
            return state;
    }
}

export function purchaseSubmitHasError(state = false, action) {
    switch (action.type) {
        case PURCHASE_SUBMIT_HAS_ERROR:
            return action.hasError;

        default:
            return state;
    }
}

export function purchasesHasError(state = false, action) {
    switch (action.type) {
        case PURCHASE_FETCH_HAS_ERROR:
            return action.hasError;

        default:
            return state;
    }
}

export function purchasesIsLoading(state = false, action) {
    switch (action.type) {
        case PURCHASE_FETCH_DATA_IS_LOADING:
            return action.isLoading;

        default:
            return state;
    }
}