import React from 'react';
import { Card, CardSubtitle, CardText, CardBody, CardHeader, Button } from 'reactstrap';
import "../styles/FeaturedContentCard.css";
import Icon from '../../Common/components/Icon';
import { Link } from 'react-router-dom';

const FeaturedContentCard = (props) => {
    const colorRef = props.index === 0 ? "--osu-violet" : props.index === 1 ? "--osu-teal" : "--osu-gray";
    const icon = props.data.icon ? props.data.icon : props.data.achievementBadge ? props.data.achievementBadge : props.data.category && props.data.category.categoryImage ? props.data.category.categoryImage : 'question';
    let link = typeof props.data.action === 'string' ? String(props.data.action).toLowerCase() : ""
    const actionableLink = link.startsWith("go to ") ? link.replace("go to ", "") : link;
    return <div className={(props.noCols ? "" : props.inner ? "col-xs-12 col-md-6" : "col-xs-12 col-md-6")}>
        <Card className={"featuredContentCard paper " + (props.noBottomMargin ? "" : "mb-3 ")}>
            <CardHeader className="d-flex flex-row align-items-center" style={{ backgroundColor: `var(${colorRef})` }}>
                <Icon type={props.data.percent > 99 ? `check` : icon} invertedCircle className="mr-2" color={colorRef} />
                <div className="flex-column featured-card-heading">
                    {React.createElement(props.headingDesignation, {className: "featured-card-heading"}, (props.isFeatured ? props.data.altText : props.data.title))}
                    {props.data.subheading && props.data.subheading.length > 0 && <CardSubtitle className="mt-1">{props.data.subheading}</CardSubtitle>}
                </div>
            </CardHeader>
            <CardBody className="d-flex flex-column">
                <CardText className="featured-text-wrapper">{props.data.subText}</CardText>
                {props.isFeatured
                    ? <Link tabIndex="-1" className="featured-button align-self-center" to={(props.isFeatured && props.data.id) ? `/feature/${props.data.id}` : actionableLink ? `/${actionableLink}` : '' }>
                        <Button className="w-100" outline>
                            {props.data.action ? `Explore ${actionableLink}` : "Start"}
                        </Button>
                    </Link>
                    : <Button className="featured-button align-self-center" outline onClick={props.handleClick(props.data.id)}>{props.data.completed ? "Review" : props.data.percent > 0 ? "Continue" : "Start"}</Button>
                }
            </CardBody>
        </Card>
    </div>
}

FeaturedContentCard.defaultProps = {
    isFeatured: true,
    headingDesignation: "h4"
}

export default FeaturedContentCard;