import { API } from "aws-amplify";
import { ACHIEVEMENT_SELECTED, ACHIEVEMENTS_HAVE_ERROR, ACHIEVEMENTS_ARE_LOADING, ACHIEVEMENTS_FETCH_DATA_SUCCESS,
    CATEGORY_SELECTED, CATEGORIES_HAVE_ERROR, CATEGORIES_NO_ERROR, CATEGORIES_ARE_LOADING, CATEGORIES_FETCH_DATA_SUCCESS,
    CONTENT_LIST_HAS_ERROR, CONTENT_LIST_IS_LOADING, CONTENT_LIST_FETCH_DATA_SUCCESS, CONTENT_HAS_ERROR,
    CONTENT_IS_LOADING, CONTENT_FETCH_DATA_SUCCESS, COMPLETED_CONTENT_HAS_ERROR, COMPLETED_CONTENT_NO_ERROR,
    COMPLETED_CONTENT_IS_LOADING, COMPLETED_CONTENT_FETCH_DATA_SUCCESS, CONTENT_COMPLETE_POST_IS_LOADING,
    CONTENT_COMPLETE_POST_SUCCESS, CONTENT_COMPLETE_POST_HAS_ERROR, CONTENT_ALL_CARD_DATA_IS_LOADING,
    CONTENT_ALL_CARD_DATA_SUCCESS, CONTENT_ALL_CARD_DATA_HAS_ERROR, CONTENT_ALL_CARD_DATA_NO_ERROR,
    CLEAR_CONTENT_STATE, QUIZ_DATA_IS_LOADING, QUIZ_DATA_SUCCESS, QUIZ_DATA_HAS_ERROR, QUIZ_DATA_NO_ERROR,
    QUIZ_SUBMIT_IS_LOADING, QUIZ_SUBMIT_SUCCESS, QUIZ_SUBMIT_HAS_ERROR, CLEAR_QUIZ_SUBMIT_STATE } from "../actions-index";
import { userContentCompletePostSuccess } from '../Home/actions';

export function achievementSelected(id){
    return {
        type: ACHIEVEMENT_SELECTED,
        id
    };
}

export function achievementsHaveError(bool) {
    return {
        type: ACHIEVEMENTS_HAVE_ERROR,
        hasError: bool
    };
}

export function achievementsAreLoading(bool) {    
    return {
        type: ACHIEVEMENTS_ARE_LOADING,
        isLoading: bool
    };
}

export function achievementsFetchDataSuccess(achievements) {    
    return {
        type: ACHIEVEMENTS_FETCH_DATA_SUCCESS,
        achievements
    };
}

export function achievementsFetchData() {
    return (dispatch) => {
        dispatch(achievementsAreLoading(true));

        API.get(process.env.REACT_APP_API_NAME, "/achievements")
            .then((response) => {
                dispatch(achievementsAreLoading(false));
                dispatch(achievementsFetchDataSuccess(response.data))
            })
            .catch(() => dispatch(achievementsHaveError(true)));
    };
}

export function categorySelected(id){
    return {
        type: CATEGORY_SELECTED,
        id
    };
}

export function categoriesHaveError(bool) {
    return {
        type: bool ? CATEGORIES_HAVE_ERROR : CATEGORIES_NO_ERROR,
        hasError: bool
    };
}

export function categoriesAreLoading(bool) {    
    return {
        type: CATEGORIES_ARE_LOADING,
        isLoading: bool
    };
}

export function categoriesFetchDataSuccess(categories) {    
    return (dispatch) => {
        dispatch(categoriesHaveError(false));
        dispatch({
            type: CATEGORIES_FETCH_DATA_SUCCESS,
            categories
        });
    };
}

export function categoriesFetchData() {
    return (dispatch) => {
        dispatch(categoriesAreLoading(true));

        API.get(process.env.REACT_APP_API_NAME, "/categories")
            .then((response) => {
                dispatch(categoriesAreLoading(false));
                dispatch(categoriesFetchDataSuccess(response.data))
            })
            .catch(() => dispatch(categoriesHaveError(true)));
    };
}

export function contentListHasError(bool) {
    return {
        type: CONTENT_LIST_HAS_ERROR,
        hasError: bool
    };
}

export function contentListIsLoading(bool) {    
    return {
        type: CONTENT_LIST_IS_LOADING,
        isLoading: bool
    };
}

export function contentListFetchDataSuccess(contentList) {    
    return {
        type: CONTENT_LIST_FETCH_DATA_SUCCESS,
        contentList
    };
}

export function contentHasError(bool) {
    return {
        type: CONTENT_HAS_ERROR,
        hasError: bool
    };
}

export function contentIsLoading(bool) {    
    return {
        type: CONTENT_IS_LOADING,
        isLoading: bool
    };
}

export function contentFetchDataSuccess(content) {    
    return (dispatch) => {
        dispatch(contentHasError(false));
        dispatch({
            type: CONTENT_FETCH_DATA_SUCCESS,
            content
        });
    };
}


export function completedContentHasError(bool) {
    return {
        type: bool ? COMPLETED_CONTENT_HAS_ERROR : COMPLETED_CONTENT_NO_ERROR,
        hasError: bool
    };
}

export function completedContentIsLoading(bool) {    
    return {
        type: COMPLETED_CONTENT_IS_LOADING,
        isLoading: bool
    };
}

export function completedContentFetchDataSuccess(content) {    
    return (dispatch) => {
        dispatch(completedContentHasError(false));
        dispatch({
            type: COMPLETED_CONTENT_FETCH_DATA_SUCCESS,
            content
        });
    };
}

export function contentCompletePostIsLoading(bool) {    
    return {
        type: CONTENT_COMPLETE_POST_IS_LOADING,
        isLoading: bool
    };
}

export function contentCompletePostSuccess(data) {    
    return {
        type: CONTENT_COMPLETE_POST_SUCCESS,
        data
    };
}

export function contentCompletePostHasError(bool) {
    return {
        type: CONTENT_COMPLETE_POST_HAS_ERROR,
        hasError: bool
    };
}

export function contentAllCardDataIsLoading(bool) {    
    return {
        type: CONTENT_ALL_CARD_DATA_IS_LOADING,
        isLoading: bool
    };
}

export function contentAllCardDataSuccess(contentList) {
    return (dispatch) => {
        dispatch(contentAllCardDataHasError(false));
        dispatch({
            type: CONTENT_ALL_CARD_DATA_SUCCESS,
            contentList
        });
    };
    
}
export function contentAllCardDataHasError(bool) {    
    return {
        type: bool ? CONTENT_ALL_CARD_DATA_HAS_ERROR : CONTENT_ALL_CARD_DATA_NO_ERROR,
        hasError: bool
    };
}

export function contentFetchData(id) {
    return (dispatch) => {
        dispatch(contentIsLoading(true));

        API.get(process.env.REACT_APP_API_NAME, `/content/${id}`)
            .then((response) => {
                dispatch(contentIsLoading(false));
                dispatch(contentFetchDataSuccess(response.data))
            })
            .catch(() => dispatch(contentHasError(true)));
    };
}

export function completedContentFetchData() {
    return (dispatch) => {
        dispatch(completedContentIsLoading(true));

        API.get(process.env.REACT_APP_API_NAME, "/user/content")
            .then((response) => {
                dispatch(completedContentIsLoading(false));
                dispatch(completedContentFetchDataSuccess(response.data))
            })
            .catch(() => dispatch(completedContentHasError(true)));
    };
}

export function contentAllCardData(){
    return (dispatch) => {
        dispatch(contentAllCardDataIsLoading(true));

        API.get(process.env.REACT_APP_API_NAME, "/content")
            .then((response) => {
                dispatch(contentAllCardDataIsLoading(false));
                dispatch(contentAllCardDataSuccess(response.data))
            })
            .catch(() => dispatch(contentAllCardDataHasError(true)));
    };
}

export function clearContentState(){
    return {
        type: CLEAR_CONTENT_STATE,
        content: ""
    };
}

export function quizIsLoading(bool) {    
    return {
        type: QUIZ_DATA_IS_LOADING,
        isLoading: bool
    };
}

export function quizFetchDataSuccess(quiz) {
    return (dispatch) => {
        dispatch(quizHasError(false));
        dispatch({
            type: QUIZ_DATA_SUCCESS,
            quiz
        });
    };

}
export function quizHasError(bool) {    
    return {
        type: bool ? QUIZ_DATA_HAS_ERROR : QUIZ_DATA_NO_ERROR,
        hasError: bool
    };
}

export function quizFetchData(id) {
    return (dispatch) => {
        dispatch(quizIsLoading(true));

        API.get(process.env.REACT_APP_API_NAME, `/quiz/${id}`)
            .then((response) => {
                dispatch(quizIsLoading(false));
                dispatch(quizFetchDataSuccess(response.data))
            })
            .catch(() => dispatch(quizHasError(true)));
    };
}

export function quizSubmitIsLoading(bool) {    
    return {
        type: QUIZ_SUBMIT_IS_LOADING,
        isLoading: bool
    };
}

export function quizSubmitSuccess(quizResult) {    
    return {
        type: QUIZ_SUBMIT_SUCCESS,
        quizResult
    };

}
export function quizSubmitHasError(bool) {    
    return {
        type: QUIZ_SUBMIT_HAS_ERROR,
        hasError: bool
    };
}

export function quizSubmit(quizData) {
    return (dispatch) => {
        dispatch(quizSubmitIsLoading(true));

        API.post(process.env.REACT_APP_API_NAME, "/quiz/evaluate", { body: quizData })
            .then((response) => {
                API.get(process.env.REACT_APP_API_NAME, `/content/quiz/${quizData.id}`)
                .then(response2 => {
                    response.data.quizRelatedContent = response2.data;
                    return response;
                });
                dispatch(quizSubmitIsLoading(false));
                
                console.log("response.data.quizResult", response.data.quizResult);
                console.log("response.data.user", response.data.user);

                dispatch(userContentCompletePostSuccess(response.data.user))
                return response;
            })
            .then((response) => dispatch(quizSubmitSuccess(response.data.quizResult)))
            .catch(() => dispatch(quizSubmitHasError(true)));
    };
}

export function quizSubmitClear(){
    return {
        type: CLEAR_QUIZ_SUBMIT_STATE,
        quizResult: ""
    };
}