import React, {Component} from 'react';
import PropTypes from 'prop-types';

class PageHeader extends Component {
    render() {
        return (
            <h2 id="page-header" ref={el => this.props.setRef(el)} tabIndex="-1" className={`float-left mb-2 ${this.props.className}`}>{this.props.title}</h2>
        );
    }
}

PageHeader.defaultProps = {
    setRef: () => {}
}

PageHeader.propTypes = {
    setRef: PropTypes.func
}

export default PageHeader;