import { USER_ASSOCIATION_FETCH_DATA_SUCCESS, UPDATE_USER_OPTOUT_STATE } from "../actions-index";

export function association(state = [], action) {
    switch (action.type) {
        case USER_ASSOCIATION_FETCH_DATA_SUCCESS:
            return action.association;

        default:
            return state;
    }
}

export function updateUserOptOutState(state = "", action = {}) {
    return (action.type === UPDATE_USER_OPTOUT_STATE) ? action.state : state;
}