import { API } from "aws-amplify";
import { ADD_TO_CART, REMOVE_FROM_CART, USER_ADDRESS_HAS_ERROR, USER_ADDRESS_IS_LOADING, USER_ADDRESS_FETCH_DATA_SUCCESS,
    PICKUP_LOCATIONS_HAS_ERROR, PICKUP_LOCATIONS_IS_LOADING, PICKUP_LOCATIONS_FETCH_DATA_SUCCESS,
    UPDATE_USER_AVAILABLE_LEVELS } from "../actions-index";

export function addToCart(reward) {    
    return {
        type: ADD_TO_CART,
        reward
    };
}

export function removeFromCart(rewardId) {    
    return {
        type: REMOVE_FROM_CART,
        rewardId
    };
}

export function userAddressFetchData() {
    console.log("user address fetch data");
    return (dispatch) => {
        dispatch(userAddressIsLoading(true));
        API.get(process.env.REACT_APP_API_NAME, "/user/address")
            .then((response) => {
                console.log("userAddressFetchData response", response)
                dispatch(userAddressIsLoading(false));
                dispatch(userAddressFetchDataSuccess(response.data ? response.data : {}))
            })
            .catch(() => dispatch(userAddressHasError(true)));
    };
}

export function userAddressHasError(bool) {
    console.log("user address has error");
    return {
        type: USER_ADDRESS_HAS_ERROR,
        hasError: bool
    };
}

export function userAddressIsLoading(bool) {
    console.log("user address is loading");
    return {
        type: USER_ADDRESS_IS_LOADING,
        isLoading: bool
    };
}

export function userAddressFetchDataSuccess(address) {
    console.log("user address fetch data success", address);
    return {
        type: USER_ADDRESS_FETCH_DATA_SUCCESS,
        address
    };
}

export function pickupLocationsFetchData(user) {
    console.log("pickup locations fetch data");
    return (dispatch) => {
        dispatch(pickupLocationsIsLoading(true));
        API.get(process.env.REACT_APP_API_NAME, "/reward/pickupLocations")
            .then((response) => {
                dispatch(pickupLocationsIsLoading(false));
                dispatch(pickupLocationsFetchDataSuccess(response.data ? response.data : {}))
            })
            .catch(() => dispatch(pickupLocationsHasError(true)));
    };
}


export function pickupLocationsHasError(bool) {
    console.log("pickup locations has error");
    return {
        type: PICKUP_LOCATIONS_HAS_ERROR,
        hasError: bool
    };
}

export function pickupLocationsIsLoading(bool) {
    console.log("pickup locations is loading");
    return {
        type: PICKUP_LOCATIONS_IS_LOADING,
        isLoading: bool
    };
}

export function pickupLocationsFetchDataSuccess(pickupLocations) {
    console.log("pickup locations fetch data success", pickupLocations);
    return {
        type: PICKUP_LOCATIONS_FETCH_DATA_SUCCESS,
        pickupLocations
    };
}

export function updateUserAvailableLevels(rewardLevels) {
    console.log("update user available levels");
    return {
        type: UPDATE_USER_AVAILABLE_LEVELS,
        rewardLevels
    };
}