import React from 'react';
import "../styles/Nav.css";
import { Link } from 'react-router-dom';
import { Nav, NavItem, Button } from 'reactstrap';
import { scroller } from "react-scroll";
import Icon from '../../Common/components/Icon';

function scrollTo(e, element) {
    e.preventDefault();
    scroller.scrollTo(element, {
        duration: 1500,
        delay: 0,
        offset: -50,
        smooth: "easeInOutCubic"
    });

    return true;
}

const RewardsSidebar = (props) => (
    <div role="navigation" aria-label="side" className={`${props.className} rewards-sidebar`}>
        {props.levels && props.levels.length > 0 ?
            <div>
                <h6 className="subheading">Available</h6>
                <div className="available-levels d-flex flex-column">
                    {props.levels.map((level) =>
                        props.disabledLevelLinks ?
                            <div key={props.appendingIdentifier + level} onClick={(e) => scrollTo(e, props.appendingIdentifier + level)}>{`Level ${level}`}</div>
                            : <Button color="link" className={"text-left p-0 osu-link-secondary"} key={props.appendingIdentifier + level} onClick={(e) => scrollTo(e, props.appendingIdentifier + level)}>{`Level ${level}`}</Button>

                    )}
                </div></div> : ""}
        <Nav>
            <NavItem className="mb-3">
                <Link className="osu-red-link" to={"/cart"} {...(props.pathname === "/cart" ? { "aria-current": "page" } : {})}>
                    <Icon className="mr-2" size="lg" type="shopping-cart" aria-hidden="true" />
                    Cart ({props.cartCount})
                </Link>
            </NavItem>
            <NavItem>
                <Link className="osu-red-link" to={"/purchases"} {...(props.pathname === "/purchases" ? { "aria-current": "page" } : {})}>
                    <Icon className="mr-2" size="lg" type="gift" aria-hidden="true" />
                    Purchases
                </Link>
            </NavItem>
        </Nav>
    </div>
)

export default RewardsSidebar;