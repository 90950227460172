import React, { Component } from 'react';
import Avatar from 'react-avatar';
// import { Link } from 'react-router-dom';
import "../styles/Profile.css";
import Icon from '../../Common/components/Icon';
import { Card, CardHeader, CardBody } from 'reactstrap';
import levelInformation from '../../util/levelInformation';
import { truncate } from "lodash";

const ACHIEVEMENT_TITLE_MAX_LENGTH = 30;
const OPT_OUT_COMMUNICATIONS = "communications";
// const OPT_OUT_LEADERBOARD = "leaderboard";

class ProfileSummary extends Component {
    constructor(props) {
        super(props);
        this.state = {
            optOut: null
        }
    }

    componentWillReceiveProps(props) {
        if(props.optOut) this.setState({ optOut: props.optOut });
    }

    componentDidUpdate(prevProps) {
        if(prevProps.updateOptOutState !== "error" && this.props.updateOptOutState === "error") {
            this.setState({ optOut: this.props.optOut }); // reset to original state
        }
    }

    onOptOutChange = (key, value) => {
        const optOut = {...this.state.optOut};
        optOut[key] = value;
        this.setState({ optOut }, () => {
            this.props.updateOptOut(this.props.userId, this.state.optOut);
        });
    }

    render() {
        const calcuation = levelInformation.calculate(this.props.points);
        const userTotalPoints = calcuation.currentPoints;
        const level = calcuation.userLevel;

        return (
            <div className={`${this.props.className} d-flex flex-column`}>
                <section id="profile-summary-section" aria-labelledby="profile-summary-header" {...(!this.props.fullWidth ? { tabIndex: "0" } : {})}>
                    <Card className="paper-light">
                        <CardHeader className="d-flex flex-row align-items-center">
                            <Avatar name={this.props.displayName} size={40} round={true} />
                            <h3 id="profile-summary-header" className="ml-2 mb-0 profile-summary-header">
                                <span className="sr-only">User Profile Summary: </span>{this.props.displayName}
                            </h3>
                            {this.props.fullWidth && <p className="mb-0 ml-auto text-left text-uppercase subheading">{level < 1 ? 'Entry ' : ''}Level{level >= 1 ? ` ${level}` : ''}</p>}
                        </CardHeader>
                        <CardBody>
                            <div className={`d-flex ${!this.props.fullWidth ? 'flex-column' : 'row'}`}>
                                <div className={`${this.props.fullWidth ? 'col-xs-12 col-sm-6' : 'd-flex flex-row'}`}>
                                    <div className={`flex-column`}>
                                        <h4 className="text-left subheading">{this.props.area}</h4>
                                        <p className="text-left profile-title">{this.props.title}</p>
                                        {this.props.fullWidth && renderLevelingStats(userTotalPoints)}
                                        {this.props.fullWidth && this.state.optOut &&
                                            <div className={`pt-2 mt-2 action-sheet d-flex flex-column`}>
                                                <h4 className="sr-only">Opt Out</h4>
                                                {this.props.updateOptOutState === "error" &&
                                                    <div role="alert" aria-live="assertive" className="text-danger">Failed to update opt out preferences.</div>
                                                }
                                                <div>
                                                    <label htmlFor="optOutCommunications" className="d-block m-0 p-0">
                                                        <input type="checkbox" id="optOutCommunications" className="mr-1"
                                                            checked={this.state.optOut.communications} onChange={(e) => this.onOptOutChange(OPT_OUT_COMMUNICATIONS, e.target.checked)} />
                                                        Click here to opt out of newsletter communications.
                                                    </label>
                                                    {/* <label htmlFor="optOutLeaderboard" className="d-block m-0 p-0">
                                                        <input type="checkbox" id="optOutLeaderboard" className="mr-1"
                                                            checked={this.state.optOut.leaderboard} onChange={(e) => this.onOptOutChange(OPT_OUT_LEADERBOARD, e.target.checked)} />
                                                        Click here to hide your information in the <Link to={"/leaderboard"}>leaderboard</Link>.
                                                    </label> */}
                                                </div>
                                            </div>
                                        }
                                    </div>
                                </div>
                                {!this.props.fullWidth && renderLevelingStats(userTotalPoints)}
                                <div className={!this.props.fullWidth ? 'flex-column pt-2 mt-2 action-sheet' : 'col-xs-12 col-sm-6'}>
                                    {renderBadges(this.props.achievements, this.props.fullWidth)}
                                </div>
                            </div>
                        </CardBody>
                        {this.props.children}
                    </Card>
                </section>
            </div>
        );
    }
}

const renderLevelingStats = (userTotalPoints = 0) =>{
    return (
        <div className={`pt-2 mt-2 action-sheet d-flex flex-column`}>
            <h4 className="text-left text-uppercase subheading">Total Score</h4>
            <p>{userTotalPoints} Points</p>
        </div>
    )
}

const renderBadges = (achievements, fullWidth = null) => {
    return [
        <h4 key="badge-heading subheading" className={`${fullWidth ? 'badges-container' : ''} text-left text-uppercase subheading`}>Badges</h4>,
        achievements && achievements.length > 0 ?
            <div className="d-flex" key="badge wrapper">
                {achievements.map((achievement, i) =>
                    <div key={`Achievement badge ${i}`} title={achievement.title} className="mr-2">
                        <span aria-hidden="true">
                            <Icon color={style(achievement)} type={`${achievement.badge ? achievement.badge : achievement.achievementBadge ? achievement.achievementBadge : 'question'}`} size="3x" className="d-block" />
                        </span>
                        <span className="achievement-title text-center d-block mt-1">
                            {(achievement.title && achievement.title.length > ACHIEVEMENT_TITLE_MAX_LENGTH) ? 
                                truncate(achievement.title, { length: ACHIEVEMENT_TITLE_MAX_LENGTH, omission: "..." }) : achievement.title}
                        </span>
                    </div>
                )}
            </div>
            :
            <p key="no-badges">No badges found!</p>
    ]
}
const style = (data) => {
    return (data.backgroundColor
                ? data.backgroundColor
                : "var(--osu-gray)")
}

ProfileSummary.defaultProps = {
    fullWidth: false,
    className: ""
}

export default ProfileSummary;