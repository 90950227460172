const RADIX = 10;
const LEVEL_SUMMARY = process.env.REACT_APP_LEVEL_SUMMARY.split(',').map((levelPoints)=> parseInt(levelPoints, RADIX));
const HIGHEST_DEFINED_LEVEL = LEVEL_SUMMARY.length - 1;
const HIGHEST_DEFINED_LEVEL_POINTS = LEVEL_SUMMARY[LEVEL_SUMMARY.length - 1];
const POINTS_TO_GAIN_LEVEL = parseInt(process.env.REACT_APP_POINTS_TO_GAIN_LEVEL, RADIX);
const POINTS_SUMMARY = LEVEL_SUMMARY.slice(-HIGHEST_DEFINED_LEVEL);

exports.calculate = (userPoints) => {
  const currentPoints = userPoints ? parseInt(userPoints, RADIX) : 0;
  const levelCalculation = POINTS_SUMMARY.findIndex((pointsToGain) => pointsToGain > currentPoints);
  const userLevel = ~levelCalculation ? levelCalculation : parseInt( (currentPoints - HIGHEST_DEFINED_LEVEL_POINTS) / POINTS_TO_GAIN_LEVEL, RADIX ) + HIGHEST_DEFINED_LEVEL;
  const pointsInCurrentLevel = calculatePointsInCurrentLevel(currentPoints, userLevel, levelCalculation);
  const pointsToNextLevel = calculateNextLevelPoints(currentPoints, userLevel, levelCalculation);


  return {userLevel, currentPoints, pointsInCurrentLevel, pointsToNextLevel}
}

const calculateNextLevelPoints = (currentPoints, userLevel, levelCalculation) => {
  let nextLevelPoints = 0;
  if(userLevel >= 0 && userLevel < HIGHEST_DEFINED_LEVEL){
    nextLevelPoints = POINTS_SUMMARY[levelCalculation];
  } else if (userLevel >= HIGHEST_DEFINED_LEVEL){
    nextLevelPoints = (userLevel - HIGHEST_DEFINED_LEVEL + 1) * POINTS_TO_GAIN_LEVEL + HIGHEST_DEFINED_LEVEL_POINTS
  }
  return nextLevelPoints - currentPoints;
}

const calculatePointsInCurrentLevel = (currentPoints, userLevel, levelCalculation) => {
  let previousLevelPoints = 0;
  if(userLevel > 0 && userLevel < HIGHEST_DEFINED_LEVEL){
    previousLevelPoints = POINTS_SUMMARY[levelCalculation - 1];
  } else if (userLevel >= HIGHEST_DEFINED_LEVEL){
    previousLevelPoints = (userLevel - HIGHEST_DEFINED_LEVEL) * POINTS_TO_GAIN_LEVEL + HIGHEST_DEFINED_LEVEL_POINTS
  }
  return currentPoints - previousLevelPoints;
}

exports.HIGHEST_DEFINED_LEVEL = HIGHEST_DEFINED_LEVEL;
exports.HIGHEST_DEFINED_LEVEL_POINTS = HIGHEST_DEFINED_LEVEL_POINTS;
exports.POINTS_TO_GAIN_LEVEL = POINTS_TO_GAIN_LEVEL;
exports.LEVEL_SUMMARY = LEVEL_SUMMARY;
