import { withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import App from '../components';
import { authSignOut, setUser } from '../../actions-index';

const mapStateToProps = (state) => {
    const { authentication, cart } = state
    const { status = '', user: authUser = {} } = authentication
    return {
        cart,
        isAuthenticated: state.isAuthenticated,
        authUser: { ...authUser, status },
        user: state.user
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setAuthUser: (forceRedirect) => {
            dispatch(setUser(forceRedirect));
        },
        signOut: () => dispatch(authSignOut()),
    };
};


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
