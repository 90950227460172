import {
    SET_USER,
    USER_STATUS
} from '../actions-index';
import { ACTION_STATUS_SUCCESS } from "../util/constants";

export function authentication(state = {}, action = {}) {
    const { payload = {} } = action
    switch (action.type) {
        case SET_USER:
            return {
                user: payload,
                status: payload.osuid ? ACTION_STATUS_SUCCESS : state.status
            };
        case USER_STATUS:
            return {
                ...state,
                status: action.payload
            };
        default:
            return state
    }
}

export function isAuthenticated(state = false, action = {}) {
    if(action.type === SET_USER && (action.payload || {}).osuid) {
        return true;
    }
    if(action.type === USER_STATUS && action.payload === ACTION_STATUS_SUCCESS) {
        return true;
    }
    return state;
}