import React from 'react';
import PropTypes from 'prop-types';
import { Form, FormGroup, FormFeedback, Button, Label, Input, Row, Col } from "reactstrap";
import '../styles/AddressForm.css';
// import * as testData from '../mockdata/testData.json';

class AddressForm extends React.Component {
    state = {
        medCenterEmployee: null
    }

    renderFormGroup = ({ label, identifier, validationMessage = "", type = "text", readOnly = false, hiddenLabel = true }) => {
        const { address } = this.props
        // const fieldInvalid = this.props.addressInvalid && validationMessage.length > 0
        // const invalidZip = fieldInvalid && identifier === "zip" && this.props.address["zip"].length < 5
        return <FormGroup>
            <Label className="no-left" for={identifier} hidden={hiddenLabel}>{label}</Label>
            <Input 
                // invalid={(fieldInvalid && (!this.props.address[identifier] || this.props.address[identifier].length < 1)) || invalidZip}
                disabled={this.props.shippingAddressConfirmed}
                readOnly={readOnly}
                type={type}
                name={identifier}
                id={identifier}
                placeholder={label}
                value={address[identifier] || ""}
                onChange={this.props.handleFieldChange.bind(this)} />
            {
                // validationMessage.length > 0 ? <FormFeedback>{invalidZip ? "Please enter a valid zipcode." : validationMessage}</FormFeedback> : null
            }
        </FormGroup>
    }

    renderPhone = () => {
        const identifier = "phone";
        const label = "Phone";
        const onBlur = event => {
            this.props.handleFieldChange(event);
            const input = event.target;
            const valid = /^[0-9]{3}-[0-9]{3}-[0-9]{4}$/.test(input.value);
            this.props.setPhoneNumberValid(valid);
            input.value === "" || valid ? input.classList.remove("is-invalid") : input.classList.add("is-invalid");
        };
        return (
            <FormGroup>
                <Label for="phone" hidden>{label}</Label>
                <Input type="text" id={identifier} name={identifier} placeholder={`${label} (Format: 123-456-7890)`} defaultValue={this.props.address[identifier] || ""} maxLength="12" onBlur={onBlur} disabled={this.props.shippingAddressConfirmed} />
                <FormFeedback>Please provide a valid phone number (Format: 123-456-7890)</FormFeedback>
                {this.props.addressInvalid && (this.props.address.phone === "" || !this.props.address.phone) && <div style={{ color: 'var(--osu-red)' }}>Please provide a phone number before proceeding.</div>}
                <p>* Phone number will only be used to contact you if there are delivery issues with rewards.</p>
            </FormGroup>
        );
    }

    updateMedCenterFlag = (bool) => {
        this.setState({
            medCenterEmployee: bool
        }, () => this.props.addMedCenterFlag(bool))
    }

    renderMedCenterEmployeeInfo = () => <Row>
        <Col xs={12} sm={6} className="pr-sm-2">
            {this.renderFormGroup({ label: "Supervisor", identifier: "supervisor", validationMessage: "Please enter the name of your supervisor." })}
        </Col>
        <Col xs={12} sm={6} className="pl-sm-0">
            {this.renderFormGroup({ label: "Floor/Room Number", identifier: "floorNumber", validationMessage: "Please enter a floor/room number." })}
        </Col>
    </Row>

    render() {
        const { addressInvalid, handleAddressSubmit, shippingAddressConfirmed, address } = this.props;
        const { medCenterEmployee } = this.state;

        return (
            <Form onSubmit={handleAddressSubmit.bind(this)} disabled>
                <FormGroup className="d-block pb-2" inline check>
                    <Label className="pl-0 pb-0">
                        Are you a Medical Center employee? *
                        {addressInvalid && !["Y", "N"].includes(address.medCenterEmployee) && <span style={{ color: 'var(--osu-red)' }} >(Required)</span>}
                    </Label>
                    <Label check>
                        <Input disabled={shippingAddressConfirmed} type="radio" name="medCenterCheck" onChange={() => this.updateMedCenterFlag(true)} />
                        Yes
                    </Label>
                    <Label check>
                        <Input disabled={shippingAddressConfirmed} type="radio" name="medCenterCheck" onChange={() => this.updateMedCenterFlag(false)} />
                        No
                    </Label>
                    {addressInvalid && !["Y", "N"].includes(address.medCenterEmployee) && <div style={{ color: 'var(--osu-red)' }} className="d-block">Please specify if you are a medical center employee before proceeding.</div>}
                </FormGroup>
                {medCenterEmployee && this.renderMedCenterEmployeeInfo()}
                {this.renderPhone()}
                {this.renderFormGroup({ label: "Additional Instructions (Optional)", identifier: "additionalInstructions" })}
                
                If you have other concerns regarding rewards, please email <a href="mailto:c4u@osu.edu">C4U@osu.edu</a>.
                <br />
                <Button disabled={shippingAddressConfirmed} className="osu-red-btn mt-4 confirm-button-position">{shippingAddressConfirmed ? "Confirmed" : "Confirm"}</Button>
            </Form>);
    }
}

AddressForm.propTypes = {
    handleFieldChange: PropTypes.func.isRequired,
    handleAddressSubmit: PropTypes.func.isRequired,
    shippingAddressConfirmed: PropTypes.bool.isRequired,
    addressInvalid: PropTypes.bool.isRequired,
    address: PropTypes.object.isRequired
}

export default AddressForm;