import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import SignIn from "../components/SignIn";
import { setUser } from "../../actions-index";

const mapStateToProps = (state) => {
    const { authentication } = state
    const { status = "", user = {} } = authentication
    return {
        user: { ...user, status }
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setUser: (forceRedirect) => dispatch(setUser(forceRedirect))
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SignIn));
