import { ADD_TO_CART, REMOVE_FROM_CART, USER_ADDRESS_FETCH_DATA_SUCCESS, PICKUP_LOCATIONS_FETCH_DATA_SUCCESS } from "../actions-index";

export function cart(state = { rewards: [] }, action) {
    switch (action.type) {
        case ADD_TO_CART:
            return {
                ...state,
                rewards: [...state.rewards, action.reward]
            };
        case REMOVE_FROM_CART:
            return {
                ...state,
                rewards: state.rewards.filter(reward => reward.rewardId !== action.rewardId)
            };
        default:
            return state;
    }
}

export function address(state = [], action) {
    switch (action.type) {
        case USER_ADDRESS_FETCH_DATA_SUCCESS:
            return action.address;

        default:
            return state;
    }
}

export function pickupLocations(state = [], action) {
    switch (action.type) {
        case PICKUP_LOCATIONS_FETCH_DATA_SUCCESS:
            return action.pickupLocations;

        default:
            return state;
    }
}