import { withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import { categoriesFetchData, contentAllCardData, featuresFetchData,
    /* welcomeFetchData,*/ rewardsFetchData, purchasesFetchData, userFetchData } from "../../actions-index";
import Home from '../components';

const mapStateToProps = (state, ownProps) => {
    return {
        categories: state.categories,
        categoriesHasError: state.categoriesHaveError,
        categoriesAreLoading: state.categoriesAreLoading,

        features: state.features,
        featuresHasError: state.featuresHaveError,
        featuresIsLoading: state.featuresAreLoading,

        content: state.contentList,
        contentHasError: state.contentListHasError,
        contentIsLoading: state.contentListIsLoading,

        user: state.user,
        userIsLoading: state.userIsLoading,
        userHasError: state.userHasError,

        isHomeLoading: [state.categoriesAreLoading, state.featuresAreLoading, state.contentListIsLoading, state.userIsLoading, state.purchasesIsLoading, state.rewardsIsLoading].some(Boolean),

        // welcome: state.welcome,
        ownProps,

        rewards: state.rewards,
        
        purchases: state.purchases
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchCardData: () => dispatch(contentAllCardData()),
        fetchFeatures: () => dispatch(featuresFetchData()),
        fetchCategories: () => dispatch(categoriesFetchData()),
        fetchRewards: () => dispatch(rewardsFetchData()),
        fetchUser: () => dispatch(userFetchData()),
        fetchPurchases: () => dispatch(purchasesFetchData()),
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Home));