import { withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import { categoriesFetchData, contentAllCardData/* , featuresFetchData */ } from "../../actions-index";
import ContentHome from '../components/ContentHome';

const mapStateToProps = (state, ownProps) => {
    return {
        categories: state.categories,
        categoriesHasError: state.categoriesHaveError,

        content: state.contentList,
        contentHasError: state.contentListHasError,

        user: state.user,
        userHasError: state.userHasError,

        isContentHomeLoading: [state.categoriesAreLoading, state.contentListIsLoading, state.userIsLoading].some(Boolean),
        
        ownProps
    };
  };
  
  const mapDispatchToProps = (dispatch) => {
    return {
        fetchCardData: () => dispatch(contentAllCardData()),
        // fetchFeatures: () => dispatch(featuresFetchData()),
        fetchCategories: () => dispatch(categoriesFetchData()),
    };
  };
  
  export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ContentHome));
  
  