import { API } from "aws-amplify";
import { REWARDS_DATA_IS_LOADING, REWARDS_DATA_SUCCESS, REDEEMED_REWARDS_DATA_SUCCESS, REWARDS_DATA_HAS_ERROR } from "../actions-index";

export function rewardsIsLoading(bool) {
    return {
        type: REWARDS_DATA_IS_LOADING,
        isLoading: bool
    };
}

export function rewardsFetchDataSuccess(rewards) {    
    return {
        type: REWARDS_DATA_SUCCESS,
        rewards
    };
}

export function redeemedRewardsFetchDataSuccess(rewards) {    
    return {
        type: REDEEMED_REWARDS_DATA_SUCCESS,
        redeemedRewards: rewards
    };
}

export function rewardsHasError(bool) {    
    return {
        type: REWARDS_DATA_HAS_ERROR,
        hasError: bool
    };
}

export function rewardsFetchData() {
    return (dispatch) => {
        dispatch(rewardsIsLoading(true));

        API.get(process.env.REACT_APP_API_NAME, "/rewards")
        .then((response) => {
            dispatch(rewardsIsLoading(false));
            dispatch(rewardsFetchDataSuccess(response.data))
        })
        .catch(() => dispatch(rewardsHasError(true)));
    };
}

export function redeemedRewardsFetchData(ids){
    return (dispatch) => {
        dispatch(rewardsIsLoading(true));
        
        let rewardsPromises = []
        for(let id of ids){
            rewardsPromises.push(API.get(process.env.REACT_APP_API_NAME, `/reward/${id}`));            
        }

        Promise.all(rewardsPromises)
            .then((responses) => {
                const rewards = responses.map(response => response.data)
                dispatch(redeemedRewardsFetchDataSuccess(rewards))
            })
            .catch((error) => {
                console.error("error in redeemedRewardsFetchData", error);
                dispatch(rewardsHasError(true))
            })
            .finally(() => dispatch(rewardsIsLoading(false)));
    }
}