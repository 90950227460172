import { API } from "aws-amplify";
import { USER_ASSOCIATION_HAS_ERROR, USER_ASSOCIATION_IS_LOADING, USER_ASSOCIATION_FETCH_DATA_SUCCESS,
    UPDATE_USER_OPTOUT_STATE, UPDATE_USER_OPTOUT } from "../actions-index";
import { ACTION_STATUS_ERROR, ACTION_STATUS_LOADING } from "../util/constants";

export function userAssociationFetchData() {
    console.log("user association fetch data");
    return (dispatch) => {
        dispatch(userAssociationIsLoading(true));
        API.get(process.env.REACT_APP_API_NAME, "/user/associations")
            .then((response) => {
                dispatch(userAssociationIsLoading(false));
                dispatch(userAssociationFetchDataSuccess(response.data && response.data[0] ? response.data[0] : {}))
            })
            .catch(() => dispatch(userAssociationHasError(true)));
    };
}

export function userAssociationHasError(bool) {
    console.log("user association has error");
    return {
        type: USER_ASSOCIATION_HAS_ERROR,
        hasError: bool
    };
}

export function userAssociationIsLoading(bool) {
    console.log("user association is loading");
    return {
        type: USER_ASSOCIATION_IS_LOADING,
        isLoading: bool
    };
}

export function userAssociationFetchDataSuccess(association) {
    console.log("user association fetch data success", association);
    return {
        type: USER_ASSOCIATION_FETCH_DATA_SUCCESS,
        association
    };
}

export function updateUserOptOut(id, optOut) {
    return dispatch => {
        dispatch({ type: UPDATE_USER_OPTOUT_STATE, state: ACTION_STATUS_LOADING });
        API.post(process.env.REACT_APP_API_NAME, "/user/optOut", { body: { id, optOut }})
        .then(response => {
            dispatch({ type: UPDATE_USER_OPTOUT, user: response.data });
            dispatch({ type: UPDATE_USER_OPTOUT_STATE, state: '' }); // reset state
        })
        .catch(() => {
            dispatch({ type: UPDATE_USER_OPTOUT_STATE, state: ACTION_STATUS_ERROR });
        });
    }
}