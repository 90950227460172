import {withRouter} from "react-router-dom";
import {connect} from 'react-redux';
import Rewards from '../components';
import {addToCart, purchasesFetchData, removeFromCart, rewardsFetchData, userFetchData } from "../../actions-index";

const mapStateToProps = (state) => {
    return {
        user: state.user,
        cart: state.cart,
        purchases: state.purchases,
        rewards: state.rewards,
        rewardsIsLoading: state.rewardsIsLoading,
        rewardsHasError: state.rewardsHasError,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchUser: () => dispatch(userFetchData()),
        fetchRewards: () => dispatch(rewardsFetchData()),
        addToCartClicked: (reward) => dispatch(addToCart(reward)),
        removeFromCartClicked: (rewardId) => dispatch(removeFromCart(rewardId)),
        purchasesFetchData: () => dispatch(purchasesFetchData()),

    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Rewards));