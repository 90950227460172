import { ACHIEVEMENTS_HAVE_ERROR, ACHIEVEMENTS_ARE_LOADING, ACHIEVEMENTS_FETCH_DATA_SUCCESS, ACHIEVEMENT_SELECTED,
    CATEGORIES_HAVE_ERROR, CATEGORIES_NO_ERROR, CATEGORIES_ARE_LOADING, CATEGORIES_FETCH_DATA_SUCCESS, CATEGORY_SELECTED,
    CONTENT_LIST_HAS_ERROR, CONTENT_ALL_CARD_DATA_HAS_ERROR, CONTENT_ALL_CARD_DATA_NO_ERROR, CONTENT_LIST_IS_LOADING,
    CONTENT_ALL_CARD_DATA_IS_LOADING, CONTENT_ALL_CARD_DATA_SUCCESS, CONTENT_LIST_FETCH_DATA_SUCCESS,
    COMPLETED_CONTENT_HAS_ERROR, COMPLETED_CONTENT_NO_ERROR, COMPLETED_CONTENT_IS_LOADING,
    COMPLETED_CONTENT_FETCH_DATA_SUCCESS, CONTENT_HAS_ERROR, CONTENT_IS_LOADING, CLEAR_CONTENT_STATE,
    CONTENT_FETCH_DATA_SUCCESS, QUIZ_DATA_HAS_ERROR, QUIZ_DATA_NO_ERROR, QUIZ_DATA_IS_LOADING,
    QUIZ_DATA_SUCCESS, QUIZ_SUBMIT_HAS_ERROR, QUIZ_SUBMIT_IS_LOADING, CLEAR_QUIZ_SUBMIT_STATE,
    QUIZ_SUBMIT_SUCCESS } from "../actions-index"; 

export function achievementsHaveError(state = false, action) {
    switch (action.type) {
        case ACHIEVEMENTS_HAVE_ERROR:
            return action.hasError;

        default:
            return state;
    }
}

export function achievementsAreLoading(state = false, action) {
    switch (action.type) {
        case ACHIEVEMENTS_ARE_LOADING:
            return action.isLoading;

        default:
            return state;
    }
}

export function achievements(state = [], action) {
    switch (action.type) {
        case ACHIEVEMENTS_FETCH_DATA_SUCCESS:
            return action.achievements;

        default:
            return state;
    }
}

export function selectedAchievement(state = [], action) {
    switch (action.type) {
        case ACHIEVEMENT_SELECTED:
            return action.id;

        default:
            return state;
    }
}

export function categoriesHaveError(state = false, action) {
    switch (action.type) {
        case CATEGORIES_HAVE_ERROR:
        case CATEGORIES_NO_ERROR:
            return action.hasError;

        default:
            return state;
    }
}

export function categoriesAreLoading(state = false, action) {
    switch (action.type) {
        case CATEGORIES_ARE_LOADING:
            return action.isLoading;

        default:
            return state;
    }
}

export function categories(state = [], action) {
    switch (action.type) {
        case CATEGORIES_FETCH_DATA_SUCCESS:
            return action.categories;

        default:
            return state;
    }
}

export function selectedCategory(state = [], action) {
    switch (action.type) {
        case CATEGORY_SELECTED:
            return action.id;

        default:
            return state;
    }
}

export function contentListHasError(state = false, action) {
    switch (action.type) {
        case CONTENT_LIST_HAS_ERROR:
        case CONTENT_ALL_CARD_DATA_HAS_ERROR:
        case CONTENT_ALL_CARD_DATA_NO_ERROR:
            return action.hasError;

        default:
            return state;
    }
}

export function contentListIsLoading(state = false, action) {
    switch (action.type) {
        case CONTENT_LIST_IS_LOADING:
        case CONTENT_ALL_CARD_DATA_IS_LOADING:
            return action.isLoading;

        default:
            return state;
    }
}

export function contentList(state, action) {
    switch (action.type) {
        case CONTENT_ALL_CARD_DATA_SUCCESS:
        case CONTENT_LIST_FETCH_DATA_SUCCESS:
            return action.contentList;

        default:
            return state || [];
    }
}

export function completedContentHasError(state = false, action) {
    switch (action.type) {
        case COMPLETED_CONTENT_HAS_ERROR:
        case COMPLETED_CONTENT_NO_ERROR:
            return action.hasError;

        default:
            return state;
    }
}

export function completedContentIsLoading(state = false, action) {
    switch (action.type) {
        case COMPLETED_CONTENT_IS_LOADING:
            return action.isLoading;

        default:
            return state;
    }
}

export function completedContent(state = null, action) {
    switch (action.type) {
        case COMPLETED_CONTENT_FETCH_DATA_SUCCESS:
            return action.content;

        default:
            return state;
    }
}

export function contentHasError(state = false, action) {
    switch (action.type) {
        case CONTENT_HAS_ERROR:
            return action.hasError;

        default:
            return state;
    }
}

export function contentIsLoading(state = false, action) {
    switch (action.type) {
        case CONTENT_IS_LOADING:
            return action.isLoading;

        default:
            return state;
    }
}

export function content(state = null, action) {
    switch (action.type) {
        case CLEAR_CONTENT_STATE:
        case CONTENT_FETCH_DATA_SUCCESS:
            return action.content;

        default:
            return state;
    }
}

export function quizHasError(state = false, action) {
    switch (action.type) {
        case QUIZ_DATA_HAS_ERROR:
        case QUIZ_DATA_NO_ERROR:
            return action.hasError;

        default:
            return state;
    }
}

export function quizIsLoading(state = false, action) {
    switch (action.type) {
        case QUIZ_DATA_IS_LOADING:
            return action.isLoading;

        default:
            return state;
    }
}

export function quiz(state = [], action) {
    switch (action.type) {
        case QUIZ_DATA_SUCCESS:
            return action.quiz;

        default:
            return state;
    }
}

export function quizSubmitHasError(state = false, action) {
    switch (action.type) {
        case QUIZ_SUBMIT_HAS_ERROR:
            return action.hasError;

        default:
            return state;
    }
}

export function quizSubmitIsLoading(state = false, action) {
    switch (action.type) {
        case QUIZ_SUBMIT_IS_LOADING:
            return action.isLoading;

        default:
            return state;
    }
}

export function quizResult(state = [], action) {
    switch (action.type) {
        case CLEAR_QUIZ_SUBMIT_STATE:
        case QUIZ_SUBMIT_SUCCESS:
            return action.quizResult;

        default:
            return state;
    }
}