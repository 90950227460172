import React, { Component } from 'react';
import CardGridGrouping from "../../Common/components/CardGridGrouping";
import Error from "../../Common/components/Error";
import { Row, Col } from 'reactstrap';
import ProgressCard from './ProgressCard';
import Loader from '../../Common/components/Loader';
import { FeaturedContentCardGrid } from './FeaturedContentCardGrid';
import levelInformation from '../../util/levelInformation';

class Home extends Component {
	constructor(props) {
        super(props);
        this.state = {
            setInitialFocus: false
        };
	}
	
	componentDidUpdate(prevProps) {
		if(prevProps.userIsLoading === true && this.props.userIsLoading === false && !this.props.userHasError === true) {
			this.props.fetchCardData();
			this.props.fetchCategories();
			this.props.fetchFeatures();
			this.props.fetchRewards();
			this.props.fetchPurchases();
			this.setState( { initial: false, ready: false});
		}
		if(this.pageHeading && this.state.setInitialFocus === false) {
            this.pageHeading.focus();
            this.setState({ setInitialFocus: true });
        }
	}

	render() {
		if ([this.props.userHasError, this.props.purchasesHasError, this.props.categoriesHasError, this.props.contentHasError].some(Boolean)) {
			return (
				<div className="container-fluid row h-100 justify-content-center align-items-center m-5">
					<Error errorMessage={this.props.userHasError ? "Error has occured. Please refresh the page." : ""} />
				</div>
			)
		}

		if(!this.props.isHomeLoading) {
			const levelsWithRewards = this.props.rewards && this.props.rewards.length > 0
			? [...new Set(this.props.rewards.filter(reward => reward.active && parseInt(reward.rewardLevel, 10) ).map(reward => reward.rewardLevel) )] 
			: [];
			const randomItem = (arr) => arr[Math.floor(Math.random() * arr.length)];
			const handledContent = contentWithHandler(this.props);
			const achievements = validatedAchievements(handledContent);
			const activities = validatedActivities(handledContent);
			const incompleteAchievements = achievements ? achievements.filter(content => content.percent > 1) : []

			return (<div>
				<h2 ref={el => (this.pageHeading = el)} tabIndex="-1" className="sr-only">Home</h2>
				<Row className="mb-4">
					<Col xs="12">
						<ProgressCard
							user={this.props.user}
							totalPoints={
								this.props.content.reduce((acc, contentItem) =>
									acc + contentItem.points, 0
								)
							}
							levelsWithRewards={levelsWithRewards}
						/>
					</Col>
				</Row>
				<h3 className="sr-only">Featured Content</h3>
				<Row className="mb-4">
					<FeaturedContentCardGrid
						featuredContentData={featuredContentData(this.props.features, achievements, activities, randomItem)}
						levelsWithRewards={levelsWithRewards}
						points={this.props.user.points}
						incompleteAchievement={incompleteAchievements.length > 0 ? randomItem(incompleteAchievements) : null}
						unstartedContentItem={determineUnstartedContentItem(achievements, randomItem, activities)}
						userRewardEligibility={userRewardEligibility(levelsWithRewards, this.props.purchases, this.props.user.points)}
						headingDesignation="h4"
					/>
				</Row>
				<CardGridGrouping showLinks={true} searchable={false} categories={this.props.categories} 
					content={[...achievements.slice(0, 3), ...activities.slice(0, 3)]} groupHeadingDesignation="h3" headingDesignation="h4" />
			</div>

			);
		} else {
			return (<Loader />);
		}
	}

}

export default Home;

const contentWithHandler = (props) => {
	const { content, user, handleContentCardClick } = props;

	return content.map(handledContent => {
		handledContent.callback = handleContentCardClick;
		if (user && user.content && user.achievements) {
			handledContent.completed = !!user.content[handledContent.id] ||
				(!!user.achievements[handledContent.id] && user.achievements[handledContent.id].status === "COMPLETE");
			handledContent.percent = user.achievements[handledContent.id] && user.achievements[handledContent.id].progress; //returns progress
		}
		return handledContent
	});

}

const validatedAchievements = (contentWithHandler = []) => {
	return contentWithHandler.filter(content => {
		if (content.type === "achievement") {
			return content.contentItemsRequired && content.contentItemsRequired.length > 0 && !content.completed
		}
		return null;
	})
}

const validatedActivities = (contentWithHandler = []) => {
	return contentWithHandler.filter(content => content.type === "article" && !content.completed);
}

const featuredContentData = (features = [], achievements = [], activities = [], randomItem) => {
	return features.length > 0
		? features[0]
		: achievements && achievements.length > 0
			? randomItem(achievements)
			: activities && activities.length > 0
				? randomItem(activities) : null
}

const userRewardEligibility = (levelsWithRewards = [], purchases = [], points = 0) => {
	const {userLevel} = levelInformation.calculate(points);
	const redeemedLevels = purchases && purchases.length > 0 ? [...new Set(purchases.map(purchase => parseInt(purchase.rewardLevel, 10)))] : []
	const filteredLevelsWithRewards = levelsWithRewards.filter(level => !redeemedLevels.includes(level));

	return userLevel >= Math.min(...filteredLevelsWithRewards)
}

const determineUnstartedContentItem = (achievements, randomItem, activities) => {
	const unstartedAchievements = achievements ? achievements.filter(content => !content.percent || content.percent === 0) : []
	return unstartedAchievements.length > 0
		? randomItem(unstartedAchievements)
		: activities && activities.length > 0
			? randomItem(activities)
			: null
}