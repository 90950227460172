import React from "react";
import PropTypes from "prop-types";
import { Link, Redirect, Route, Switch, useHistory, useLocation } from "react-router-dom";
import useAmplifyHub from "../../Common/hooks/useAmplifyHub";
import Cart from "../../Cart/containers";
import Content from "../../Content/containers";
import ContentHome from "../../Home/containers/ContentHome";
import Home from "../../Home/containers";
// import Leaderboard from "../../Leaderboard/containers";
import Profile from "../../Profile/containers";
import Purchases from "../../Purchases/containers";
import Rewards from "../../Rewards/containers";
import SignIn from "../../Authentication/containers/SignIn";
import SignOut from "../../Authentication/components/SignOut";

const APPLICATION_TITLE = "Cybersecurity For You | Ohio State";

function Routes(props) {
  const { isAuthenticated, isSupportedBrowser } = props;
  const { redirectPath } = useAmplifyHub();
  const { pathname } = useLocation();
  const history = useHistory();

  const renderPage = (component, pageTitle = null) => {
    if(!isSupportedBrowser) {
      component = (<UnsupportedBrowser />);
      pageTitle = null;
    }

    // show an empty page until authenticated
    // login and logout perform as normal
    if(!isAuthenticated && !["/login", "/logout"].includes(pathname)) {
      component = null;
      pageTitle = null;
    }

    document.title = (pageTitle ? `${pageTitle} | ${APPLICATION_TITLE}` : APPLICATION_TITLE);
    return component;  
  };

  const PageNotFound = () => {
    return (
        <div data-testid="page-not-found">
            <h2>Page Not Found</h2>
            Return to the <Link to="/">Home</Link> page.
        </div>
    );
  };

  const UnsupportedBrowser = () => {
    return (
      <div data-testid="unsupported-browser">
          <h2>Unsupported Browser</h2>
          <p>Your browser is not supported by Cybersecurity For You. Please use a different browser such as Chrome, Firefox, Edge or Safari.</p>
          <p>Please contact Cybersecurity at <a href="mailto:c4u@osu.edu">C4U@osu.edu</a> if you need assistance.</p>
          <p>Browser information: {(navigator && navigator.userAgent) ? navigator.userAgent : "Unknown"}</p>
      </div>
    );
  };

  const handleContentCardClick = (contentId) => e => {
    console.log("You clicked a content card with ID: ", contentId);
    history.push(`/content/${contentId}`);
  };

  return (
    <Switch>
      {(redirectPath && redirectPath !== "/logout") && <Redirect from={`/login`} to={redirectPath} />}
      <Route exact path="/" render={() => renderPage(<Home handleContentCardClick={handleContentCardClick} />)} />
      <Route exact path="/achievement/:id" render={() => renderPage(<Content handleContentCardClick={handleContentCardClick} />, "Achievement")} />
      <Route exact path="/achievements" render={() => renderPage(<ContentHome contentLabel="Achievements" contentType="achievement" handleContentCardClick={handleContentCardClick} />, "Achievements")} />
      <Route exact path="/activities" render={() => renderPage(<ContentHome contentLabel="Activities" contentType="article" handleContentCardClick={handleContentCardClick} />, "Activities")} />
      <Route exact path="/cart" render={() => renderPage(<Cart contentLabel="Cart" />, "Cart")} />
      <Route exact path="/content/:id" render={() => renderPage(<Content handleContentCardClick={handleContentCardClick} />, "Content")} />
      <Route exact path="/feature/:id" render={() => renderPage(<Content handleContentCardClick={handleContentCardClick} />, "Feature")} />
      {/* <Route exact path="/leaderboard" render={() => renderPage(<Leaderboard contentLabel="Leaderboard" />, "Leaderboard")} /> */}
      <Route exact path="/login" render={() => renderPage(<SignIn />)} />
      <Route exact path="/logout" render={() => renderPage(<SignOut />)} />
      <Route exact path="/profile" render={() => renderPage(<Profile category="Completed Content" handleContentCardClick={handleContentCardClick} />, "Profile")} />
      <Route exact path="/purchases" render={() => renderPage(<Purchases category="Purchases" />, "Purchases")} />
      <Route exact path="/rewards" render={() => renderPage(<Rewards contentLabel="Rewards" />, "Rewards")} />
      <Route render={() => renderPage(<PageNotFound />)} />
    </Switch>
  );
}

Routes.defaultProps = {
  isAuthenticated: false,
  isSupportedBrowser: false
};

Routes.propTypes = {
  isAuthenticated: PropTypes.bool,
  isSupportedBrowser: PropTypes.bool
};

export default Routes;
