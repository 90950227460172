import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import * as AuthenticationReducers from './Authentication/reducers';
import * as CartReducers from './Cart/reducers';
import * as ContentReducers from './Content/reducers';
import * as HomeReducers from './Home/reducers';
import * as LeaderboardReducers from './Leaderboard/reducers';
import * as ProfileReducers from "./Profile/reducers";
import * as PurchasesReducers from './Purchases/reducers';
import * as RewardsReducers from './Rewards/reducers';

export default combineReducers({ 
    form: formReducer,
    ...AuthenticationReducers,
    ...CartReducers,
    ...ContentReducers,
    ...HomeReducers,
    ...LeaderboardReducers,
    ...ProfileReducers,
    ...PurchasesReducers,
    ...RewardsReducers
})