import React from 'react';
import { Card, CardText, CardBody, CardSubtitle, CardHeader, Button } from 'reactstrap';
import "../styles/ContentCard.css";
import Icon from '../../Common/components/Icon';

const ContentCard = (props) => (
	<div className={`${props.className} ` + (props.noCols ? "" : props.inner ? "col-sm-12 col-md-6 col-lg-6 col-xl-4" : "offset-0 col-12 col-sm-12 offset-md-0 col-md-6 col-lg-4")}>
		<Card className={"content-card paper " + (props.noBottomMargin ? "" : "mb-3 ")}>
			<CardHeader
				className={"content-card__header "}
			>
				{props.data.type === 'achievement'
					? <Icon type={props.data.percent > 99 ? `check` : props.data.achievementBadge || 'question'} circle className="mr-2" color={style(props.data)} />
					: <Icon type={props.data.completed ? `check` : (props.data.category && props.data.category.categoryImage) || 'question'} circle className="mr-2" color={style(props.data)} />
				}
				<div className="content-card__heading-wrapper">
					{React.createElement(props.headingDesignation, {className: "content-card__title mb-2 card-subtitle"}, props.data.title)}
					<CardSubtitle className="d-flex">
						{!!props.data.estMinForComplete && <div className="subheading mr-4">{props.data.estMinForComplete} minutes</div>}
						{!!props.data.percent && props.data.percent < 100 && <div className="subheading mr-4">{props.data.percent}% Completed</div>}
						{props.data.type !== 'achievement' && <div className="subheading">{props.data.points} points</div>}
					</CardSubtitle>
				</div>
			</CardHeader>
			<CardBody className="content-card__body-wrapper">
				{/*{props.data.type === 'article' && <CardText className="content-card__date">{moment(props.data.createdAt).format('MMMM DD, YYYY')}</CardText>}*/}
				<CardText className={`${props.data.percent < 100 || !props.data.completed ? 'subheading font-italic' : ''}`}>{relayCompletionStatus(props.data)}</CardText>
				<CardText className="content-card__description">{props.data.subText}</CardText>
				<Button outline onClick={props.handleClick(props.data.id)} className="content-card__action-sheet">{props.data.completed ? "Review" : props.data.percent > 0 ? "Continue" : "Start"}<span className="sr-only"> {props.data.title}</span></Button>
			</CardBody>
		</Card>
	</div>
);

const relayCompletionStatus = (data) => {
	const length = data.contentItemsRequired ? data.contentItemsRequired.length : null
	if (data.type !== 'achievement') {
		return data.completed ? "Complete" : "Incomplete"
	} else {
		let count = data.percent > 0 ? (data.percent / 100) * length : "0";
		return `${Math.round(count)} of ${length} ${length !== 1 ? `Activities` : `Activity`} Completed`
	}
}

const style = (data) => {
	return (data.percent > 99 || data.completed)
		? "var(--osu-green)"
		: (data.category && data.category.backgroundColor
			? data.category.backgroundColor
			: (data.type === 'achievement'
				? (data.backgroundColor
					? data.backgroundColor
					: "var(--osu-gray)")
				: "var(--osu-red)"))
}

export default ContentCard;