import React, { Component } from 'react';
import PropTypes from 'prop-types';
import "../styles/LevelBar.css";
import Icon from '../../Common/components/Icon';

import { POINTS_TO_GAIN_LEVEL } from '../../util/levelInformation';

class LevelBar extends Component {
    constructor(props) {
        super(props);

        this.toggle = this.toggle.bind(this);
        this.state = {
            tooltipOpen: false
        };
    }

    toggle() {
        this.setState({
            tooltipOpen: !this.state.tooltipOpen
        });
    }

    render() {
        let { stops } = this.props;
        const { currentPoints, userLevel, maxLevel, maxPoints, levelPoints = (new Array(stops)).fill(POINTS_TO_GAIN_LEVEL), startingValue, levelsWithRewards } = this.props
        const filledProgressWidth = 100 * currentPoints / maxPoints;

        if (stops === 0) {
            return <div className="level-bar no-stop">
                <div className="unfilled">
                    <div className="filled" style={{ width: filledProgressWidth >= 100 ? `100%` : `${filledProgressWidth}%` }}>
                        {(filledProgressWidth < 100) ? <div id="ProgressCircleTooltip" className="circle"></div> : null}
                    </div>
                </div>
            </div>
        }

        let lowerBound = Math.floor(userLevel / stops) * stops
        if (maxLevel < stops) {
            stops = maxLevel
        } else if ((lowerBound + stops) > maxLevel) {
            lowerBound = maxLevel - stops
        }

        return (
            <div className="level-bar">
                <div className="unfilled">
                    <div className="filled" style={{ width: filledProgressWidth >= 100 ? `calc(100% + 2rem)` : `calc(${filledProgressWidth}% + 1rem)` }}>
                        {(filledProgressWidth < 100) ? <div id="ProgressCircleTooltip" className="circle"></div> : null}
                    </div>
                    <div className="level-stops">
                        { levelPoints.map((points, i) => {
                            let mappedLevel = maxLevel > stops ? i + lowerBound : i 
                            let previousLevelPoints = i > 0 ? levelPoints[i - 1] : 0;
                            let percentage = 100 * (points - previousLevelPoints) / (levelPoints[ levelPoints.length - 1 ] - levelPoints[0]);
                            return <div className="meter subheading text-uppercase"  style={ i > 0 ? { flexBasis: `${percentage}%`}: {}} key={`Level ${mappedLevel} Stop`}>
                                {levelsWithRewards && levelsWithRewards.length > 0
                                    ? levelsWithRewards.map(level => level === mappedLevel
                                        ? <Icon key={mappedLevel} type="gift" circle color="--osu-blue" className={`rewards-indicator ${i < 1 ? 'starting-value' : ''}`} />
                                        : null)
                                    : null}
                                <div className={`level-text ${i < 1 ? 'starting-value' : ''}`}>{lowerBound < 1 && i < 1 ? startingValue : 'Level ' + mappedLevel}</div>
                                <div className={`points-text ${i < 1 ? 'starting-value' : ''}`}>{points} <span>points</span></div>
                            </div>
                        }
                        )}
                    </div>
                </div>
            </div >
        )
    }
}

LevelBar.propTypes = {
    currentPoints: PropTypes.number.isRequired,
    maxPoints: PropTypes.number.isRequired,
    maxLevel: PropTypes.node.isRequired,
    userLevel: PropTypes.number.isRequired,
    stops: PropTypes.number.isRequired,
    startingValue: PropTypes.string,
    levelPoints: PropTypes.array,
    levelsWithRewards: PropTypes.array
}

 export default LevelBar