import { REWARDS_DATA_HAS_ERROR, REWARDS_DATA_IS_LOADING, REWARDS_DATA_SUCCESS, REDEEMED_REWARDS_DATA_SUCCESS } from "../actions-index";

export function rewardsHasError(state = false, action) {
    switch (action.type) {
        case REWARDS_DATA_HAS_ERROR:
            return action.hasError;

        default:
            return state;
    }
}

export function rewardsIsLoading(state = false, action) {
    switch (action.type) {
        case REWARDS_DATA_IS_LOADING:
            return action.isLoading;

        default:
            return state;
    }
}

export function rewards(state = [], action) {
    switch (action.type) {
        case REWARDS_DATA_SUCCESS:
            return action.rewards;

        default:
            return state;
    }
}

export function redeemedRewards(state = [], action) {
    switch (action.type) {
        case REDEEMED_REWARDS_DATA_SUCCESS:
            return action.redeemedRewards;

        default:
            return state;
    }
}