import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import '../styles/Article.css';
import 'react-quill/dist/quill.snow.css';
import ContentCard from '../../Common/components/ContentCard';
import * as _ from 'lodash';
import Icon from '../../Common/components/Icon';

class Article extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {}
    }

    displayRelatedContent() {
        let contentCards;

        if (!_.isEmpty(this.props.content.achievements)) {
            this.props.content.achievements.forEach(achievement => {
                if (achievement.published === 'Y' && !_.isEmpty(achievement.relatedContent)) {         
                    let relatedAchievementContent = achievement.relatedContent.filter(content => Object.keys(this.props.user.content).indexOf(content.id) === -1)          
                    contentCards = this.renderContentCards(relatedAchievementContent.slice(0, 3), "Other Available Content in this Achievement", achievement.title);
                }
            }
            )
        }
        if (_.isEmpty(contentCards)) {
            if (!_.isEmpty(this.props.content.relatedContent)) {
                let availableRelatedContent = this.props.content.relatedContent.filter(content => Object.keys(this.props.user.content).indexOf(content.id) === -1)
                contentCards = this.renderContentCards(availableRelatedContent.slice(0, 3), "Available Related Content");
            } else if (!_.isEmpty(this.props.availableAchievements)) {
                let incompleteAchievements = this.props.availableAchievements
                    .filter(achievement => {
                        return this.props.user.achievements[achievement.id] && this.props.user.achievements[achievement.id].status !== 'COMPLETE' 
                    })
                contentCards = this.renderContentCards(incompleteAchievements.slice(0, 3), "Available Achievements");
            }
        }

        return contentCards;
    }

    renderContentCards = (contentItems, title = null, subheading = null) =>
        {
            if(contentItems.length){
                let contentItemsWithCategory = contentItems.map( content => {
                    let category = (content.category && this.props.categories[content.category]) || (content.categories && this.props.categories[content.categories[0]]) || null
                    return Object.assign({}, content, {category})
                });
                return <Col xs="12"> 
                    <h2>{title ? title : "Related Content"}</h2>
                    {subheading ? <h3 className="mt-2">{subheading}</h3> : ""}
                    <Row>
                        {contentItemsWithCategory.map(content =>
                            <Col className="mt-4" xs="12" sm="6" xl="4" key={content.id}>
                                <ContentCard
                                    noCols={true}
                                    key={content.id}
                                    data={content}
                                    handleClick={content.callback}
                                    headingDesignation="h4" />
                            </Col>
                        )}
                    </Row>

                </Col>;
            }
        }


    render() {

        var sections = [];

        if (this.props.content.sections) {
            sections = this.props.content.sections.map((section, index) => {
                let imgEl = React.createRef();
                let qlVideoWrapperDiv = { start: '<div class="ql-video-wrapper">', end: '</div>'}
                section.text = section.text.replace(/<iframe class="ql-video".*?<\/iframe>/g, (match) => qlVideoWrapperDiv.start + match + qlVideoWrapperDiv.end );
                return (
                    <div className="mb-5" key={index} >
                        {section.title ? <h2 className="article-section-title">{section.title}</h2> : ""}
                        <div className="content-section" dangerouslySetInnerHTML={{ __html: section.text }} />
                        {section.image && section.image.url ?
                            <center>
                                <div className="article-image">
                                    <img 
                                        style={{width: "80%"}}
                                        ref={imgEl} 
                                        onLoad={() => {
                                            let img = imgEl.current;
                                            let imgStyle = img.style;
                                            let naturalImgWidth = img.naturalWidth;
                                            imgStyle.maxWidth =  naturalImgWidth + "px";
                                        }}
                                        src={section.image.url} 
                                        alt={section.image.caption} 
                                    />
                                    <div className="article-image-caption">{section.image.caption}</div>
                                </div>
                            </center>
                            : ""
                        }
                    </div>
                );
            }
            );
        }

        return (
            <div>
                {this.props.content.banner &&
                    <div className={"article-banner-image"}
                        style={this.props.content.banner ?
                            { "backgroundImage": `url(${this.props.content.banner})` }
                            : {}} alt="banner image" />}
                <div className="container-fluid ql-editor">
                    <Row className="article no-row-margins">
                        <Col xs="12" lg={{ size: 10, offset: 1 }}>
                            <h1>{this.props.content.title}</h1>
                            <h2 className="article-subtext">{this.props.content.subText}</h2>
                        </Col>
                        <Col xs="12" lg={{ size: 10, offset: 1 }} className="mt-3">
                            <div className="subheading">
                                {!this.props.children.props.completed
                                    ? <div>
                                        {this.props.content.estMinForComplete
                                            ? <span className="mr-4">
                                                <Icon type="clock-o" className="mr-2" />
                                                {this.props.content.estMinForComplete + " Minutes"}
                                            </span>
                                            : ""}
                                        {this.props.content.points
                                            ? <span>
                                                <Icon type="star" className="mr-2" />
                                                {this.props.content.points + " Points"}
                                            </span>
                                            : ""}
                                    </div>
                                    : <span> <Icon type="check" className="mr-1" />Content Completed</span>
                                }
                            </div>
                        </Col>
                    </Row>
                    <Row className="article no-row-margins">
                        <Col xs="12" lg={{ size: 10, offset: 1 }}>
                            {sections}
                        </Col>
                    </Row>
                    {this.props.children.props.quizId &&
                        <Row className="article no-row-margins">
                            <Col xs="12">
                                {this.props.children}
                            </Col>
                        </Row>}
                    <Row className="article no-row-margins">
                        {this.displayRelatedContent()}
                    </Row>
                </div>
            </div>
        );
    }
}

export default Article;