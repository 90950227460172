import React from "react";
import PropTypes from 'prop-types';

const Icon = (props) => {

    const { ariaLabel, type, className, size, circle = false, invertedCircle = false, color = "" } = props;
    const stringWithfa = (value = "") => value.startsWith("fa-") ? value : 'fa-' + value
    const transformedType = type ? stringWithfa(type) : ''
    const transformedSize = size ? stringWithfa(size) : ''
    const transformedColor = (value = "") =>
        (value.startsWith("#") || value.startsWith("rgb") || value.startsWith("var("))
            ? { color: value }
            : value.length > 0
                ? { color: `var(${value})` }
                : null

    const concatenatedClass = `fa ${transformedType} ${transformedSize} ${className ? className : ''}`.trim();

    if (transformedType.length > 0) {
        if (circle || invertedCircle) {
            return <span aria-label={ariaLabel}  className={`fa-stack ${size ? transformedSize : 'fa-lg'} ${className ? className : ''}`.trim()} aria-hidden="true">
                <i className={`fa fa-circle fa-stack-2x`} style={invertedCircle ? { color: 'white' } : color ? transformedColor(color) : null} >
                </i>
                <i className={`fa ${transformedType} fa-stack-1x ${invertedCircle ? '' : 'fa-inverse'}`.trim()} style={invertedCircle ? transformedColor(color) : null}></i>
            </span>
        }
        return <i aria-label={ariaLabel} className={concatenatedClass} style={color.length > 0 ? transformedColor(color) : null}/>
    }
}

Icon.propTypes = {
    ariaLabel: PropTypes.string,
    type: PropTypes.string.isRequired,
    className: PropTypes.string,
    size: PropTypes.string,
    circle: PropTypes.bool,
    invertedCircle: PropTypes.bool,
    color: PropTypes.string
}

export default Icon