import React from "react";
import PropTypes from "prop-types";
import Routes from "../../Routes/components";
import { ACTION_STATUS_ERROR, ACTION_STATUS_LOADING, ACTION_STATUS_SUCCESS } from "../../util/constants";

function Main(props = {}) {
  const { className, id, isAuthenticated, isSupportedBrowser } = props;
  return (
    <main className={className} id={id}>
      <h1 className="sr-only">Cybersecurity For You</h1>
      <Routes isAuthenticated={isAuthenticated} isSupportedBrowser={isSupportedBrowser} />
    </main>
  );
}

Main.defaultProps = {
  isAuthenticated: false,
  isSupportedBrowser: false,
  user: {
    status: "",
  },
};

Main.propTypes = {
  isAuthenticated: PropTypes.bool,
  isSupportedBrowser: PropTypes.bool,
  user: PropTypes.shape({
    status: PropTypes.oneOf([ACTION_STATUS_ERROR, ACTION_STATUS_LOADING, ACTION_STATUS_SUCCESS, ""]),
  }),
  setUser: PropTypes.func,
};

export default Main;
