import React from 'react';
import { Col } from 'reactstrap';
import PurchasesTable from './Table';
import RewardsNavContainer from '../../Rewards/containers/Nav';
import { Link } from 'react-router-dom';
import moment from 'moment';
import Error from '../../Common/components/Error';
import Loader from '../../Common/components/Loader';
import '../styles/Purchases.css';

class Purchases extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            setInitialFocus: false
        };
    }

    componentDidMount() {
        let {user, purchases, fetchUser, fetchRewards, purchasesFetchData} = this.props;
        if (!user.id) {
            fetchUser();
        }
        
        purchasesFetchData();

        if (purchases.length > 0) {
            let purchaseIds = purchases.map(purchase => purchase.rewardId);
            fetchRewards(purchaseIds);
        }
        
    }

    componentDidUpdate(prevProps){
        const {user, rewards, purchases, fetchRewards} = this.props;
        if( ((JSON.stringify(prevProps.user) !== JSON.stringify(user)) ||
            (JSON.stringify(prevProps.rewards) !== JSON.stringify(rewards)) ||
            (JSON.stringify(prevProps.purchases) !== JSON.stringify(purchases))) &&
            user.id
        ){
            let purchaseIds = purchases.map(purchase => purchase.rewardId);
            fetchRewards(purchaseIds);
        }

        if(this.pageHeading && this.state.setInitialFocus === false) {
            this.pageHeading.focus();
            this.setState({ setInitialFocus: true });
        }
    }

    componentWillUnmount() {
        this.props.clearRewards(); // clear rewards so that they are re-pulled to match purchases when returning to the page
    }

    renderPurchaseData(purchases, rewards) {
        return purchases.map((purchase) => {
            const matchingReward = rewards.find((reward) => (reward.rewardId === purchase.rewardId))
            const purchaseRow = {
                level: purchase.rewardLevel,
                title: matchingReward.title,
                datePurchased: moment(purchase.redemptionDate, 'YYYYMMDD').format('MM/DD/YYYY'),
                status: purchase.redemptionStatus.toLowerCase()
            }
            return purchaseRow;
        }
        )
    }

    render() {
        const { cart, user, rewards, rewardsIsLoading, rewardsHasError, purchases, purchasesHasError, purchasesIsLoading } = this.props;
        const validPurchases = purchases && rewards && purchases.length > 0 && rewards.length > 0;
        if (purchasesHasError || rewardsHasError) {
            return (
                <div className="container-fluid row h-100 justify-content-center align-items-center m-5">
                    <Error errorMessage={purchasesHasError ? "Error has occured. Contact C4U@osu.edu for any issues." : ""} />
                </div>
            );
        }
        if (!purchasesIsLoading && !rewardsIsLoading) {
            return (
                <RewardsNavContainer
                    cartCount={cart.rewards.length ? cart.rewards.length : 0}
                    availableLevels={user.availableLevels}
                    disabledLevelLinks
                    pathname={this.props.location.pathname}
                >
                    <Col xs="12 mb-4">
                        <h2 ref={el => (this.pageHeading = el)} tabIndex="-1">Purchases</h2>
                        {validPurchases ? PurchasesTable(this.renderPurchaseData(purchases, rewards)) :
                            <div>
                                <h2 className="mt-4 mb-4 purchase-not-found">No purchases found. Head over to our <Link className="osu-link-secondary" to={"/rewards"}>rewards store</Link> to see what's available!</h2>
                                <hr></hr>
                            </div>
                        }
                        <p className="subheading mt-4">Previous year’s reward purchases will not appear here. Unfulfilled rewards from any year will still be shipped when possible. Contact <a href="mailto:c4u@osu.edu">C4U@osu.edu</a> with any questions or concerns.</p>
                    </Col>
                </RewardsNavContainer>
            );
        } else {
            return <Loader/>
        }

    }
}

export default Purchases;