import React from 'react';
import "../styles/FeedbackCard.css";
import Icon from '../../Common/components/Icon';

const FeedbackCard = (props) => {
    return <div className={`d-flex flex-row flex-1 ${props.className}`}>
        <Icon type={props.badge} color={props.color} circle />
        <div className="d-flex flex-column ml-2">
            <h2 className="subheading text-uppercase">{props.heading}</h2>
            {props.children}
        </div>
    </div>
}

FeedbackCard.defaultProps = {
    className: "",
    badge: "star",
    color: "--osu-gold",
    heading: "your results"
}
export default FeedbackCard;