import {withRouter} from "react-router-dom";
import {connect} from 'react-redux';
import { pickupLocationsFetchData, removeFromCart, submitPurchase, updateUserAvailableLevels, userAddressFetchData } from "../../actions-index";
import Cart from '../components';

const mapStateToProps = (state) => {
    return {
        user: state.user,
        cart: state.cart,
        address: state.address,
        pickupLocations: state.pickupLocations
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        updateUserAvailableLevels: (rewardLevels) => dispatch(updateUserAvailableLevels(rewardLevels)),
        fetchUserAddress: () => dispatch(userAddressFetchData()),
        fetchPickupLocations: () => dispatch(pickupLocationsFetchData()),
        removeFromCartClicked: (rewardId) => dispatch(removeFromCart(rewardId)),
        submitPurchase: (values) => dispatch(submitPurchase(values))
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Cart));