import React from 'react';
import "../styles/Nav.css";
import { Link } from 'react-router-dom';
import { Nav } from 'reactstrap';
import Icon from '../../Common/components/Icon';

const RewardsHorizontalBar = (props) =>
    (<Nav className="pull-right">
        <div className="paper p-4 m-2">
            <Link className="osu-red-link mb-2 mr-5" to={"/cart"}>
                <Icon className="mr-2" size="lg" type="shopping-cart" />
                Cart ({props.cartCount})
        </Link>
            <Link className="osu-red-link" to={"/purchases"}>
                <Icon className="mr-2" size="lg" type="gift" />
                Purchases
        </Link>
        </div>
    </Nav>)

export default RewardsHorizontalBar