import React, { Component } from 'react';
import { CardDeck } from 'reactstrap';
import ContentCard from '../../Common/components/ContentCard';
import * as _ from 'lodash';
import { Link } from 'react-router-dom';
import "../styles/CardGrid.css";

const responsive = {
    default: 4,
    sizes: {
        768: 1,
        992: 2,
        1420: 3,
    }

};

class CardGridGrouping extends Component {

    constructor(props) {
        super(props);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.updateResponsiveness = this.updateResponsiveness.bind(this);
        this.state = { input: "", responsive: responsive.default };
    }

    // Need this for carousel
    componentDidMount() {
        this.updateResponsiveness();
        window.addEventListener("resize", this.updateResponsiveness);
        setTimeout(() => {
            window.dispatchEvent(new Event('resize'));
        }, 0);
    }

    componentWillReceiveProps(newProps) {
        setTimeout(() => {
            window.dispatchEvent(new Event('resize'));
        }, 0);
    }

    componentWillUnmount() {
        this.isCancelled = true;
    }

    updateResponsiveness() {
        let resized = false;
        Object.keys(responsive.sizes).forEach(key => {
            if (!resized && window.innerWidth < key) {
                resized = true;
                !this.isCancelled && this.setState({
                    responsive: responsive.sizes[key]
                });
            }
        });
        if (!resized) {
            !this.isCancelled && this.setState({
                responsive: responsive.default
            });
        }
    }

    handleInputChange(evt) {
        !this.isCancelled && this.setState({ input: evt.target.value })
    }

    renderGroupData(group, filteredContent) {
        // let content = [];
        let groupFilteredContent;
        if (group.key === 'achievement' || group.key === 'article') {
            groupFilteredContent = _.filter(filteredContent, { type: group.key });
        } else {
            groupFilteredContent = _.filter(filteredContent, (obj) => {
                return obj.category && obj.category.categoryNameSearch === group.key
            })
        }

        groupFilteredContent = _.sortBy(groupFilteredContent, ['completed', 'sortPriority', 'title']).map((content) => {
            return (
                <ContentCard
                    className={"inner-card"}
                    inner={this.props.inner}
                    key={content.id}
                    data={content}
                    handleClick={content.callback} 
                    headingDesignation={this.props.headingDesignation}/>
            );
        });

        // content.push(
        //     <CardDeck className="ml-0 mr-0" key={group.key}>
        //         {groupFilteredContent}
        //     </CardDeck>
        // );

        if (groupFilteredContent.length > 0) {
            return (
                <CardDeck className="row ml-0 mr-0" key={group.key}>
                    <div className="col-12 no-col-padding d-flex justify-content-between">
                        {React.createElement(this.props.groupHeadingDesignation, {}, group.label)}
                        {this.props.showLinks && <span className="align-self-top">
                            <Link className="osu-link-secondary" to={group.label.toLowerCase()}>Explore More {group.label}</Link>
                        </span>}
                    </div>
                    <div className={"col-12 no-col-padding"}>
                        <div className="row">
                            {groupFilteredContent ? groupFilteredContent : `No ${groupFilteredContent.label} found.`}
                        </div>
                    </div>
                </CardDeck>
            );
        }

    }

    render() {
        let filteredContent = this.props.content;
        const titleToDisplay = this.props.appendToTitle && this.props.appendToTitle.length > 0 ? this.props.appendToTitle + ' ' : '';

        if (this.props.content.length > 0 && this.state.input) {
            filteredContent = _.filter(this.props.content, (obj) => {
                if (obj.title.toLowerCase().indexOf(this.state.input.toLowerCase()) >= 0) {
                    return obj;
                }
            });
            if(this.searchbarAnnouncements) {
                this.searchbarAnnouncements.textContent = `Your search returned ${filteredContent.length > 0 ? filteredContent.length : 'no'} results.`;
            }
        } else {
            if(this.searchbarAnnouncements) {
                this.searchbarAnnouncements.textContent = `Displaying all content`;
            }
        }
        let groups = [
            { key: 'article', label: `${this.props.title ? `${titleToDisplay}Activities` : ''}` },
            { key: 'achievement', label: `${this.props.title ? `${titleToDisplay}Achievements` : ''}` }
        ];

        let groupElements = [];
        if (filteredContent && filteredContent.length > 0) {
            groups.forEach(group => {
                groupElements.push(
                    this.renderGroupData(group, filteredContent)
                );
            });
        }

        const Message = (
            <div className="text-left pl-3 font-weight-bold">
                {this.state.input === "" ? "Congratulations you've completed all activities. Please check back regularly for more activities to complete." : "No results were found."}
            </div>
        );

        return (
            <div className="mb-5">
                {this.props.searchable && (
                    <div className="form-group mb-3" role="search">
                        <div className="input-group">
                            <div className="input-group-prepend" aria-hidden="true">
                                <span className="input-group-text"><i className="fa fa-search" /></span>
                            </div>
                            <label htmlFor="searchbar" className="sr-only">Search content</label>
                            <input id="searchbar" className={"form-control"} placeholder="Search" type="text" aria-describedby="searchbar-helptext" onChange={this.handleInputChange} />
                            <p id="searchbar-helptext" className="sr-only">Dynamically filters the content as you type your search.</p>
                            <div aria-live="polite" className="sr-only" ref={el => (this.searchbarAnnouncements = el)} />
                        </div>
                    </div>
                )}
                {groupElements.length > 0 ? groupElements : Message}
            </div>
        );

    }
}

CardGridGrouping.defaultProps = {
    searchable: true,
    title: true,
    appendToTitle: "",
    showLinks: false,
    groupHeadingDesignation: "h3",
    headingDesignation:"h4"
};

export default CardGridGrouping;