import React, { Component } from 'react';
import { Button, Row, Col, FormGroup, Label, Input, Alert } from "reactstrap";
import "../styles/Cart.css";
import RewardsNavContainer from '../../Rewards/containers/Nav';
import { Link } from 'react-router-dom';
import AddressForm from './AddressForm';
import { Stepper, StepperSection } from "./Stepper";
import Icon from "../../Common/components/Icon";
import { isBoolean } from 'lodash';

class Cart extends Component {

    constructor(props) {
        super(props);

        this.state = {
            address: {},
            shippingAddress: {},
            addressInvalid: false,
            shippingAddressConfirmed: false,
            stepperOpen: true,
            purchaseConfirmed: false,
            taxInfoConfirmed: false,
            formControl: {
                noAddress: false,
                pickupType: ""
            },
            setInitialFocus: false,
            phoneNumberValid: false
        };
    }

    componentDidUpdate(prevProps, prevState) {
        if (JSON.stringify(this.props.address) !== JSON.stringify(prevProps.address)) {
            if(this.props.address && !this.props.address.addressLine1)
                var noAddress = true;
            else {
                this.setState({ address: this.state.address, formControl: {noAddress: noAddress} });
            }
        }
        if(this.pageHeading && this.state.setInitialFocus === false) {
            this.pageHeading.focus();
            this.setState({ setInitialFocus: true });
        }
    }

    handleFieldChange(event) {
        console.log("this.state.address: ", this.state.address);
        console.log("this.state.address.medCenterEmployee: ", this.state.address.medCenterEmployee);
        console.log("handleFieldChange: ", event.target);
        let address = { ...this.state.address };

        address[event.target.name] = event.target.value
        address.medCenterEmployee = this.state.address.medCenterEmployee;

        this.setState({
            address,
            formControl: {
                noAddress: this.state.noAddress
            }
        })
    }

    handleAddressSubmit(event, values) {
        const { address, phoneNumberValid } = this.state
        if (
            !["Y", "N"].includes(address.medCenterEmployee)
            || !phoneNumberValid
        ){
            this.setState({
                addressInvalid: true
            })
        } else {
            let confirmedAddress = { ...this.state.address };
            
            this.setState({
                shippingAddressConfirmed: true,
                confirmedAddress
            })

            console.log("confirmedAddress", confirmedAddress)
        }

        event.preventDefault();
        
    }

    handlePurchaseConfirmation(event) {
        if (this.props.cart.rewards && this.props.user) {
            const { id, firstName, lastName, username } = this.props.user
            const purchase = {
                emplId: id,
                firstName,
                lastName,
                username,
                shippingAddress: this.state.confirmedAddress,
                rewards: this.props.cart.rewards
            }
            this.props.submitPurchase(purchase);
            this.props.updateUserAvailableLevels(this.props.cart.rewards.map((reward) => reward.rewardLevel));
            this.setState({
                stepperOpen: false,
                purchaseConfirmed: true
            })
            this.props.cart.rewards.forEach(reward => {
                this.props.removeFromCartClicked(reward.rewardId);
            })
        }
    }

    getRedemptionLevelsFromCart = () => {
        return this.props.cart.rewards.map((reward) => `Level ${reward.rewardLevel} Reward`).join(", ")
    }

    addMedCenterFlagToAddress = (bool = null) => {
        if (bool === true || bool === false) {
            this.setState((state) => ({
                address: {
                    ...state.address,
                    medCenterEmployee: bool ? 'Y' : "N"
                }
            }))
        }
    }

    setPhoneNumberValid = (bool) => {
        this.setState({ phoneNumberValid: isBoolean(bool) ? bool : false });
    }

    render() {
        return (
            <RewardsNavContainer
                cartCount={this.props.cart.rewards.length ? this.props.cart.rewards.length : 0}
                availableLevels={this.props.user.availableLevels}
                disabledLevelLinks
                pathname={this.props.location.pathname}
            >                
                <Row>
                    <Col xs={{ size: 12, offset: 0 }} md={{ size: 10, offset: 1 }} xl={{ size: 10, offset: 0 }}>
                        <h2 ref={el => (this.pageHeading = el)} tabIndex="-1">Cart</h2>
                        <Alert className="boldened-alert" color="info">
                            <p>
                                “*Digital Delivery*” rewards are provided via email which includes redemption instructions.
                            </p>
                            <p>
                                If you have questions about our new Cause Rewards,&nbsp;
                                <a href="https://go.osu.edu/c4u-faq" target="_blank" rel="noopener noreferrer">please visit the FAQ by clicking here</a>.
                            </p>
                        </Alert>
                        {this.props.cart.rewards.length || this.state.purchaseConfirmed ?
                            <Stepper className="mb-2">
                                <StepperSection inactive={!this.state.stepperOpen} heading="Confirmation" circleContent={!this.state.shippingAddressConfirmed ? 1 : <Icon type="check" />}>
                                    <Row>
                                        <Col xs={12} xl={10} className="underline">
                                            <Row className="light-gray">
                                                <Col xs={2} className="text-center">Level</Col>
                                                <Col xs={2}>Category</Col>
                                                <Col xs={4} sm={5} md={6}>Reward</Col>
                                                <Col xs={4} sm={3} md={2} className="text-center">Remove</Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                    {this.props.cart.rewards.map(reward => {
                                        return (
                                            <Row key={reward.rewardId}>
                                                <Col xs={12} xl={10}>
                                                    <div className="row light-underline">
                                                        <Col xs={2} className="text-center mt-2 mb-2">{reward.rewardLevel}</Col>
                                                        <Col xs={2} className="mt-2 mb-2">{reward.category}</Col>
                                                        <Col xs={4} sm={5} md={6} className="mt-2 mb-2">{reward.title}</Col>
                                                        <Col xs={4} sm={3} md={2} className="text-center mt-1">
                                                            <Button
                                                                disabled={this.state.shippingAddressConfirmed}
                                                                className="remove-button-up"
                                                                size="sm"
                                                                onClick={() => {
                                                                    this.props.removeFromCartClicked(reward.rewardId)
                                                                }}
                                                            >
                                                                X
                                            </Button>
                                                        </Col>
                                                    </div>
                                                </Col>
                                            </Row>
                                        );
                                    })
                                    }
                                </StepperSection>
                                <StepperSection inactive={!this.state.stepperOpen} heading="Contact Information" circleContent={!this.state.shippingAddressConfirmed ? 2 : <Icon type="check" />}>
                                    {<Row>
                                        <Col xs={12} xl={10}>
                                            <AddressForm
                                                handleFieldChange={(event) => this.handleFieldChange(event)}
                                                handleAddressSubmit={(event) => this.handleAddressSubmit(event)}
                                                shippingAddressConfirmed={this.state.shippingAddressConfirmed}
                                                addressInvalid={this.state.addressInvalid}
                                                addMedCenterFlag={(bool) => this.addMedCenterFlagToAddress(bool)}
                                                setPhoneNumberValid={this.setPhoneNumberValid}
                                                address={this.state.address}
                                            />
                                        </Col>
                                    </Row>}
                                </StepperSection>
                                {taxInfoStepperSection(this.state.stepperOpen, 3, this.state.taxInfoConfirmed, () => this.setState((state) => ({ taxInfoConfirmed: !state.taxInfoConfirmed })))}
                                <StepperSection inactive={!this.state.stepperOpen} heading="Purchases" circleContent={this.state.stepperOpen ? 4 : <Icon type="check" />}>
                                    <Row>
                                        <Col xs={12} xl={10}>
                                            <p className="mb-1">By selecting purchase below you will redeem the following reward levels:</p>
                                            <p className="mb-2"><b>{this.getRedemptionLevelsFromCart()}</b></p>
                                            <p className="mb-2">
                                                To view the status of your order, please visit <Link className="osu-link-secondary" to={"/purchases"}>Purchases</Link>. 
                                                If you have any questions please reach out to <a href="mailto:c4u@osu.edu">C4U@osu.edu</a> with your purchase information.
                                            </p>
                                            <p className="mb-4">
                                                Thank you and keep gaining levels to earn more rewards!
                                            </p>
                                            <Button
                                                className="osu-red-btn"
                                                disabled={!this.state.taxInfoConfirmed || !this.state.shippingAddressConfirmed} 
                                                onClick={this.handlePurchaseConfirmation.bind(this)}>
                                                   {!this.state.shippingAddressConfirmed ? "Please Confirm Cart and Address" : !this.state.taxInfoConfirmed ? "Please Confirm Tax Information" : "Purchase"}
                                            </Button>
                                        </Col>
                                    </Row>
                                </StepperSection>
                            </Stepper>
                            :
                            <h2 className="mt-4 mb-4 cart-empty">Your cart is empty. Head over to our <Link className="osu-link-secondary" to={"/rewards"}>rewards store</Link> to see what's available!</h2>
                        }
                    </Col>
                </Row>
                {this.state.purchaseConfirmed ?
                    <Col xs={{ size: 12, offset: 0 }} md={{ size: 10, offset: 1 }} xl={{ size: 10, offset: 0 }}>
                        <h2 className="cart-empty">Thank you for Purchasing</h2>
                        <h3>
                            Please visit
                            <Link className="osu-link-secondary" to={"/purchases"}> Purchases </Link> for updates.
                        </h3>
                    </Col>
                    : null}
            </RewardsNavContainer>
        );
    }
}

export default Cart;

function taxInfoStepperSection(stepperOpen, index, taxInfoConfirmed, confirmTaxInfo) {
    return <StepperSection inactive={!stepperOpen} heading="Tax Information" circleContent={!taxInfoConfirmed ? index : <Icon type="check" />}>
        <Row>
            <Col xs={12} xl={10}>
                <p className="mb-1">
                    By clicking here, you confirm that you understand that this reward may become 
                    taxable to you during the calendar year if you receive other non-cash rewards 
                    from the university with a cumulative value of $100.
                </p>
                <FormGroup check>
                    <Label className="pl-0" check>
                        <Input type="checkbox" onChange={confirmTaxInfo} /> I confirm
                    </Label>
                </FormGroup>
            </Col>
        </Row>
    </StepperSection>
}