import React, { Component } from 'react';
import { Row, Col, Alert } from 'reactstrap';
import CardGrid from '../../Common/components/CardGrid';
import RewardsNavContainer from '../containers/Nav';
import * as _ from 'lodash';
import '../styles/Rewards.css';
import Loader from '../../Common/components/Loader';
import levelInformation from '../../util/levelInformation';

/*Cart status variables*/
const IN_CART = "IN_CART";
const AVAILABLE = "AVAILABLE";
const UNAVAILABLE_F0R_CART = "UNAVAILABLE_F0R_CART";
const UNAVAILABLE_F0R_USER = "UNAVAILABLE_F0R_USER";
const REWARD_TYPE = "reward";
const LEVEL_SUMMARY = levelInformation.LEVEL_SUMMARY;
const HIGHEST_DEFINED_LEVEL = levelInformation.HIGHEST_DEFINED_LEVEL;
const POINTS_TO_GAIN_LEVEL = levelInformation.POINTS_TO_GAIN_LEVEL;

class Rewards extends Component {
    constructor(props) {
        super(props);
        this.addToCart = this.addToCart.bind(this);
        this.removeFromCart = this.removeFromCart.bind(this);
        this.state = {
            setInitialFocus: false
        };
    }

    addToCart(reward, category) {
        reward.category = category;
        this.props.addToCartClicked(reward)
    }

    removeFromCart(rewardId) {
        this.props.removeFromCartClicked(rewardId)
    }

    isLevelRedeemed(rewardLevel) {
        let purchasedLevels = this.props.purchases.map((purchase) => purchase.rewardLevel);
        return purchasedLevels.indexOf(rewardLevel.toString()) > -1;
    }

    userEligibility(rewardLevel) {
        let userPoints = isNaN(this.props.user.points) ? 0 : this.props.user.points;
        const { userLevel } = levelInformation.calculate(userPoints);
        return userLevel >= rewardLevel && !this.isLevelRedeemed(rewardLevel);
    }

    getNewRewardStatus(newReward, rewardsInCart) {
        let status = AVAILABLE;
        if (this.userEligibility(newReward.rewardLevel)) {
            if (rewardsInCart.length > 0) {
                rewardsInCart.forEach(reward => {
                    if (reward.rewardId === newReward.rewardId) {
                        status = IN_CART;
                    } else if (reward.rewardLevel === newReward.rewardLevel) {
                        status = UNAVAILABLE_F0R_CART;
                    }
                })
            }
        } else {
            status = UNAVAILABLE_F0R_USER;
        }
        return status;
    }

    createGroupedStoreRewards(rewardsArr, addToCart, removeFromCart, rewardsInCart = []) {
        let self = this;
        return rewardsArr.reduce(function (groupedRewards, currentReward) {
            var key = currentReward['rewardLevel'];

            if (!groupedRewards[key]) {
                groupedRewards[key] = [];
            }

            let newReward = Object.assign({}, currentReward);
            newReward.type = REWARD_TYPE;
            newReward.status = self.getNewRewardStatus(newReward, rewardsInCart);
            newReward.addToCartClicked = (category) => addToCart(newReward, category);
            newReward.removeFromCartClicked = () => removeFromCart(newReward.rewardId);

            groupedRewards[key].push(newReward);
            return groupedRewards;
        }, {});
    }

    calculateLevelPoints(level) {
        return level <= HIGHEST_DEFINED_LEVEL
            ? LEVEL_SUMMARY[level]
            : LEVEL_SUMMARY[HIGHEST_DEFINED_LEVEL] + (level - HIGHEST_DEFINED_LEVEL) * POINTS_TO_GAIN_LEVEL
    }

    renderRewardSection(rewards, levelGroupIdentifier) {
        let headRewardSection = [];
        let tailRewardSection = [];
        const rewardSection = (level) => {
            return <div key={level} id={levelGroupIdentifier + level}>
                <h3>Level {level} Rewards - {this.calculateLevelPoints(level)} Points</h3>
                <div className="subheading">
                    {
                        this.userEligibility(level) ?
                            "Available. Choose your reward!" :
                            (this.isLevelRedeemed(level)) ?
                                "Redeemed! Thanks!" :
                                "Not there yet! Level up to earn these rewards!"
                    }
                </div>

                <CardGrid noMargins={true} searchable={false} inner={true} category=" "
                    contentLabel="Rewards" content={rewards[level]} />
            </div>
        }
        const levels = Object.keys(rewards);
        for (let level of levels) {
            let isUserEligible = this.userEligibility(level);
            let isLevelRedeemed = this.isLevelRedeemed(level);
            if (isUserEligible || !isLevelRedeemed) {
                headRewardSection.push(rewardSection(level));
            } else {
                tailRewardSection.push(rewardSection(level));
            }
        }
        return headRewardSection.concat(tailRewardSection);
    }

    availableRewards(rewardsObj, level) {
        let self = this;
        return _.pickBy(rewardsObj, function (arrayOfRewardsForLevel, rewardLevel) {
            return parseInt(rewardLevel, 10) <= parseInt(level, 10) && self.userEligibility(rewardLevel)
        })
    }

    findNextRewardLevel(rewards, level) {
        let levels = Object.keys(rewards).sort((a, b) => a - b);
        let nextLevel;
        for (let i = 0; i < levels.length; i++) {
            if (levels[i] > level) {
                nextLevel = levels[i];
                break;
            }
        }
        return nextLevel;
    }

    nextRewards(rewards, level) {
        let nextRewards = {};
        let nextLevel = this.findNextRewardLevel(rewards, level);
        if (nextLevel) {
            nextRewards[nextLevel] = rewards[nextLevel];
        }
        return nextRewards;

    }

    unavailableRewards(rewardsObj, level) {
        let availableRewards = Object.keys(this.availableRewards(rewardsObj, level));
        let nextRewardLevel = this.findNextRewardLevel(rewardsObj, level);
        return _.pickBy(rewardsObj, function (arrayOfRewardsForLevel, rewardLevel) {
            return nextRewardLevel !== rewardLevel && availableRewards.indexOf(rewardLevel) === -1;
        })
    }

    getAvailableLevels = (rewards) => {
        let self = this;
        return Object.keys(rewards)
            .filter((rewardId) => {
                return self.userEligibility(rewardId) ? rewardId : null
            })
    }

    componentDidMount() {
        this.props.fetchRewards();
        if (!this.props.user.id) {
            this.props.fetchUser();
        }
        this.props.purchasesFetchData();
    }

    componentDidUpdate() {
        if (this.pageHeading && this.state.setInitialFocus === false) {
            this.pageHeading.focus();
            this.setState({ setInitialFocus: true });
        }
    }

    render() {
        if (this.props.rewardsIsLoading) {
            return (<Loader />);
        }

        const userPoints = isNaN(this.props.user.points) ? 0 : this.props.user.points;
        const { userLevel } = levelInformation.calculate(userPoints);
        const activeRewards = Array.isArray(this.props.rewards) && this.props.rewards.length > 0 ? this.props.rewards.filter(reward => reward.active === true) : []
        let currentRewards = this.createGroupedStoreRewards(activeRewards, this.addToCart, this.removeFromCart, this.props.cart.rewards);
        this.props.user.availableLevels = this.getAvailableLevels(currentRewards);
        const appendingIdentifierForSectionNavigation = "sectionId";
        const availableAwardsSection = this.renderRewardSection(this.availableRewards(currentRewards, userLevel), appendingIdentifierForSectionNavigation)
        const nextAwardsSection = this.renderRewardSection(this.nextRewards(currentRewards, userLevel), appendingIdentifierForSectionNavigation)
        const unavailableAwardsSection = this.renderRewardSection(this.unavailableRewards(currentRewards, userLevel), appendingIdentifierForSectionNavigation)

        return (
            <RewardsNavContainer
                cartCount={this.props.cart.rewards.length ? this.props.cart.rewards.length : 0}
                availableLevels={this.props.user.availableLevels}
                pathname={this.props.location.pathname}
            >
                <Row className="mb-4">
                    <Col xs="12">
                        <h2 ref={el => this.pageHeading = el} tabIndex="-1">{this.props.contentLabel}</h2>
                        <Alert className="boldened-alert" color="info">
                            <p>
                                “*Digital Delivery*” rewards are provided via email which includes redemption instructions.
                            </p>
                            <p>
                                If you have questions about our new Cause Rewards,&nbsp;
                                <a href="https://go.osu.edu/c4u-faq" target="_blank" rel="noopener noreferrer">please visit the FAQ by clicking here</a>.
                            </p>
                        </Alert>
                        <p>Every level you achieve you get a reward. No need to save your points for higher level prizes!</p>
                        <p>Once you select your reward, go to your cart to check out.</p>
                        <p>Thanks for your participation!</p>
                    </Col>
                </Row>
                {availableAwardsSection}
                {nextAwardsSection}
                {unavailableAwardsSection}
            </RewardsNavContainer>
        );
    }

}

export default Rewards;