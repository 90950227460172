import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import {  withRouter } from "react-router-dom";
import 'es6-shim';
import '../styles/Feature.css';
import 'react-quill/dist/quill.snow.css';
import CardGridGrouping from "../../Common/components/CardGridGrouping";

class Feature extends Component {

  constructor(props){
    super(props);
    this.findEstMinForCompleteAchievement = this.findEstMinForCompleteAchievement.bind(this);
  }

  findEstMinForCompleteAchievement(content){
    let result = this.props.contentList.find(function(obj) {
      return obj.id === content.id;
    });
    return result ? result.estMinForComplete : null;
  }

  render() {
    let contentWithHandler;
    if(this.props.content.relatedContent.length > 0){
      contentWithHandler = this.props.content.relatedContent.map(content => {
          if (this.props.user && this.props.user.content && this.props.user.achievements) {
            content.completed = !!this.props.user.content[content.id] ||
                (!!this.props.user.achievements[content.id] && this.props.user.achievements[content.id].status === "COMPLETE");
            content.percent = this.props.user.achievements[content.id] && this.props.user.achievements[content.id].progress; //returns progress
            content.estMinForComplete = content.estMinForComplete || this.findEstMinForCompleteAchievement(content);

          }
        return content;
      }); 
    }

    return (
      <div>
        {this.props.content.banner &&
          <div className={"article-banner-image"}
              style={this.props.content.banner ?
                  {"backgroundImage": `url(${this.props.content.banner})`}
                  : {}} alt="feature image"/>}
        <div className="container-fluid ql-editor ">
        <Row className="article">
          <Col xs="12" className="ml-3">
              <h1>{this.props.content.title}</h1>
              <h2 className="subheading">{this.props.content.subText}</h2>
              <div className="content-section">{this.props.content.description}</div>
          </Col>
          <Col xs="12" className="ml-3">
            <h2>Featured Content</h2>
          </Col>        
          <Col xs="12" className={(this.props.noMargins ? " no-col-padding" : "")}>
            <CardGridGrouping
            content={contentWithHandler}/>
          </Col>
        </Row>  
      </div>
    </div>  
    );
  }
}

export default withRouter(Feature);