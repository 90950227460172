import React from 'react';
import { Row, Col } from 'reactstrap';

const PurchasesTable = (purchases) => {
    return (
        <div>
            <Row key="header" className="d-none d-sm-block">
                <Col sm={12} className="underline">
                    <Row className="subheading">
                        <Col sm={1}>Level</Col>
                        <Col sm={7}>Reward</Col>
                        <Col sm={2}>Purchased On</Col>
                        <Col sm={2}>Status</Col>
                    </Row>
                </Col>
            </Row>
            {purchases.map((purchase, key) => {
                return <Row key={key}>
                    <Col xs={12}>
                        <Row className="pt-3 pb-3 light-underline">
                            <Col sm={1} className="d-none d-sm-block">{purchase.level}</Col>
                            <Col xs={10} sm={7}>{purchase.title}
                                <h6 className="subheading d-block d-sm-none pt-2">Level {purchase.level} Reward</h6>
                                <h6 className="subheading d-block d-sm-none">Purchased On: {purchase.datePurchased}</h6>
                                <h6 className="subheading d-block d-sm-none text-capitalize">Status: {purchase.status}</h6>
                            </Col>
                            <Col className="d-none d-sm-block" sm={2}>{purchase.datePurchased}</Col>
                            <Col className="d-none d-sm-block text-capitalize" sm={2}>{purchase.status}</Col>
                        </Row>
                    </Col>
                </Row>
            })}
        </div>
    )
}

export default PurchasesTable;