import { connect } from 'react-redux';
import { completedContentFetchData, contentAllCardData, userAssociationFetchData, userFetchData, updateUserOptOut  } from "../../actions-index";
import Profile from '../components';

const mapStateToProps = (state, ownProps) => {
  return {
    user: state.user,
    userHasError: state.userHasError,
    updateUserOptOutState: state.updateUserOptOutState,
    content: state.contentList,
    completedContent: state.completedContent,
    completedContentHasError: state.completedContentHasError,
    userAssociation: state.association,
    ownProps
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
      fetchCompletedContent: () => dispatch(completedContentFetchData()),
      fetchUserAssociation: () => dispatch(userAssociationFetchData()),
      fetchUser: () => dispatch(userFetchData()),
      fetchCardData: () => dispatch(contentAllCardData()),
      updateUserOptOut: (id, optOut) => dispatch(updateUserOptOut(id, optOut))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Profile);